import {
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "@tanstack/react-query";
import { useAccessToken, useStandardErrorHandler } from "modules/common";
import { CodebookType } from "modules/common/constants/codebookType";
import { toast } from "react-toastify";

import {
    CodebookEntry,
    CodebookEntryResponse,
    createCodebookEntry,
    CreateCodebookEntryRequest,
    deactivateCodebookEntry,
    DeactivateCodebookEntryRequest,
    editCodebookEntry,
    EditCodebookEntryRequest,
    GetCodebookEntriesRequest,
    getCodebookEntries,
} from "services/codebooks";

export const useGetCodebookEntries = (
    data: GetCodebookEntriesRequest,
    options?: UseQueryOptions<CodebookEntryResponse>
) => {
    const errorHandler = useStandardErrorHandler("Greška pri dohvaćanju šifrarnika");
    const accessToken = useAccessToken();
    const { enabled, ...opts } = options ?? {};

    const queryFn = () => getCodebookEntries(data, accessToken ?? "");

    return useQuery({
        queryKey: ["codebooks", data],
        queryFn,
        onError: errorHandler,
        keepPreviousData: true,
        enabled: data.codebookType !== 0,
        ...opts,
    });
};

const useGetCodeBookEntriesAsItems = (codebookType: CodebookType) => {
    const { data: codebooksData } = useGetCodebookEntries({
        page: 1,
        pageSize: 100,
        codebookType: codebookType,
        isActive: true,
    });

    if (!codebooksData?.items || codebooksData.items?.length === 0) {
        return [];
    }

    return codebooksData?.items.map(item => ({
        label: item.name,
        id: item.guid,
        professionalAreaGuid: item.professionalAreaGuid,
    }));
};

export const useGetInterventionAsItems = () =>
    useGetCodeBookEntriesAsItems(CodebookType.Intervention);
export const useGetProfessionalAreasAsItems = () =>
    useGetCodeBookEntriesAsItems(CodebookType.ProfessionalArea);
export const useGetProductionTypesAsItems = () =>
    useGetCodeBookEntriesAsItems(CodebookType.ProductionType);

export const useCreateCodebookEntry = (
    options?: UseMutationOptions<CodebookEntry, unknown, CreateCodebookEntryRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri stvaranju unosa šifrarnika");

    return useMutation({
        mutationFn: (data: CreateCodebookEntryRequest) => createCodebookEntry(data, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["codebooks"]);
            toast.success("Unos u šifrarnik je uspješno stvoren");
        },
        ...options,
    });
};

export const useEditCodebookEntry = (
    options?: UseMutationOptions<CodebookEntry, unknown, EditCodebookEntryRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri uređivanju unosa šifrarnika");

    return useMutation({
        mutationFn: (data: EditCodebookEntryRequest) => editCodebookEntry(data, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["codebooks"]);
            toast.success("Unos u šifrarniku je uspješno uređen");
        },
        ...options,
    });
};

export const useDeactivateCodebookEntry = (
    options?: UseMutationOptions<CodebookEntry, unknown, DeactivateCodebookEntryRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri deaktivaciji unosa šifrarnika");

    return useMutation({
        mutationFn: (data: DeactivateCodebookEntryRequest) =>
            deactivateCodebookEntry(data, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["codebooks"]);
            toast.success("Unos u šifrarnik je uspješno deaktiviran");
        },
        ...options,
    });
};
