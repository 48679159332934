import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    EducationApplicationResponse,
    EducationByIdResponse,
    EducationListItems,
    EducationResponse,
    StatusResponse,
} from "services";

interface State {
    isLoadingEducations: boolean;
    isLoadingReminders: boolean;
    errorMessage: string | undefined;
    educations: EducationResponse | null;
    appliedEducations: EducationResponse | null;
    listenedEducations: EducationResponse | null;
    educationById: EducationByIdResponse | null;
    educationApplication?: EducationApplicationResponse;
    educationApplicationError?: boolean;
    educationReminder?: StatusResponse;
    educationReminderError?: boolean;
    educationsWithReminders?: EducationListItems;
    allReminders?: EducationListItems;
}

const initialState: State = {
    isLoadingEducations: false,
    isLoadingReminders: false,
    errorMessage: undefined,
    educations: null,
    appliedEducations: null,
    listenedEducations: null,
    educationById: null,
    educationApplication: undefined,
    educationApplicationError: undefined,
    educationReminder: undefined,
    educationReminderError: false,
    educationsWithReminders: undefined,
    allReminders: undefined,
};

const educationsSlice = createSlice({
    name: "Educations",
    initialState: initialState,
    reducers: {
        getEducationsRequest: state => {
            state.isLoadingEducations = true;
        },
        getEducationsSuccess: (state, action: PayloadAction<{ educations: EducationResponse }>) => {
            state.isLoadingEducations = false;
            state.educations = action.payload.educations;
        },
        getEducationsWithRemindersRequest: state => {
            state.isLoadingReminders = true;
        },
        getAppliedEducationsSuccess: (
            state,
            action: PayloadAction<{ educations: EducationResponse }>
        ) => {
            state.isLoadingEducations = false;
            state.appliedEducations = action.payload.educations;
        },
        getListenedEducationsSuccess: (
            state,
            action: PayloadAction<{ educations: EducationResponse }>
        ) => {
            state.isLoadingEducations = false;
            state.listenedEducations = action.payload.educations;
        },
        getEducationByIdSuccess: (
            state,
            action: PayloadAction<{ education: EducationByIdResponse }>
        ) => {
            state.isLoadingEducations = false;
            state.educationById = action.payload.education;
        },
        getEducationsError: (state, action: PayloadAction<{ errorMessage: string }>) => {
            state.isLoadingEducations = false;
            state.errorMessage = action.payload.errorMessage;
        },
        getEducationApplicationSuccess: (
            state,
            action: PayloadAction<{ response: EducationApplicationResponse }>
        ) => {
            state.isLoadingEducations = false;
            state.educationApplication = action.payload.response;
        },
        getEducationsApplicationError: (state, action: PayloadAction<{ error: boolean }>) => {
            state.isLoadingEducations = false;
            state.educationApplicationError = action.payload.error;
        },
        getEducationReminderSuccess: (
            state,
            action: PayloadAction<{ response: StatusResponse }>
        ) => {
            state.isLoadingEducations = false;
            state.educationReminder = action.payload.response;
        },
        getEducationReminderError: (state, action: PayloadAction<{ error: boolean }>) => {
            state.isLoadingEducations = false;
            state.educationReminderError = action.payload.error;
        },
        getEducationsWithRemindersSuccess: (
            state,
            action: PayloadAction<{ educations?: EducationListItems }>
        ) => {
            state.isLoadingReminders = false;
            state.educationsWithReminders = action.payload.educations;
        },
        getEducationsWithReminderError: (state, action: PayloadAction<{ error: boolean }>) => {
            state.isLoadingReminders = false;
            state.educationReminderError = action.payload.error;
        },
        getAllReminders: (state, action: PayloadAction<{ reminders?: EducationListItems }>) => {
            state.isLoadingReminders = false;
            state.allReminders = action.payload.reminders;
        },
    },
});

const { actions } = educationsSlice;

export const {
    getEducationsRequest,
    getEducationsSuccess,
    getEducationsWithRemindersRequest,
    getAppliedEducationsSuccess,
    getListenedEducationsSuccess,
    getEducationByIdSuccess,
    getEducationsError,
    getEducationApplicationSuccess,
    getEducationsApplicationError,
    getEducationReminderSuccess,
    getEducationReminderError,
    getEducationsWithRemindersSuccess,
    getEducationsWithReminderError,
    getAllReminders,
} = actions;

export const educationsReducer = educationsSlice.reducer;
