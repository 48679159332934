import { Button, useTitle } from "modules/common";
import { PanelMenu, PanelMenuItem } from "modules/common/components/PanelMenu";
import { useState } from "react";
import { CodebookDetailsModal, CodebooksTable, useGetCodebookEntries } from "modules/codebooks";
import { GetCodebookEntriesRequest } from "services/codebooks";
import { CodebookType } from "modules/common/constants/codebookType";

const initialRequest: GetCodebookEntriesRequest = {
    page: 1,
    pageSize: 10,
    codebookType: CodebookType.Intervention,
};

const codebooks: PanelMenuItem[] = [
    {
        label: "Intervencija",
        description: "predstavlja mjeru odnosno obvezu poljoprivrednika",
        link: "Intervencija",
    },
    {
        label: "Stručno područje",
        description: "predstavlja stručno područje djelovanja s portala savjetodavna.hr",
        link: "Stručno područje",
    },
    {
        label: "Vrsta proizvodnje",
        description: "predstavlja točnu kategoriju unutar stručnog područja",
        link: "Vrsta proizvodnje",
    },
];

const menuItems: PanelMenuItem[] =
    codebooks.map(item => ({
        label: item.label,
        link: item.link,
        description: item.description,
    })) ?? [];

const getCodeBooktype = (label: string) => {
    switch (label) {
        case "Intervencija":
            return CodebookType.Intervention;
        case "Stručno područje":
            return CodebookType.ProfessionalArea;
        case "Vrsta proizvodnje":
            return CodebookType.ProductionType;
        default:
            return CodebookType.Unknown;
    }
};

export const CodebookAdministration = () => {
    useTitle("Sifrarnik - eSavjetnik");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [menuItem, setMenuItem] = useState<PanelMenuItem>(menuItems[0]);
    const [request, setRequest] = useState<GetCodebookEntriesRequest>(initialRequest);

    const { data: codebookData, isLoading } = useGetCodebookEntries(request);

    return (
        <div className="codebook-container">
            <div className="codebook-heading">
                <div className="codebook-filters">
                    <span> ŠIFRARNIK</span>
                </div>
                <Button
                    size="small"
                    className="codebook-button"
                    variant="primary"
                    isDisabled={!menuItem || isLoading}
                    onClick={() => setIsModalOpen(true)}
                >
                    KREIRAJ NOVI UNOS
                </Button>
            </div>
            <div className="codebook-content">
                <PanelMenu
                    items={menuItems}
                    selectedItem={menuItem}
                    onItemClicked={item => {
                        setMenuItem(item);
                        setRequest({
                            ...request,
                            codebookType: getCodeBooktype(item.label),
                            page: 1,
                        });
                    }}
                />
                {menuItem && (
                    <CodebooksTable
                        codebooks={codebookData}
                        isLoading={isLoading}
                        codebookType={getCodeBooktype(menuItem?.label ?? "")}
                        onPageChange={page => setRequest({ ...request, page })}
                        onPageSizeChange={pageSize => setRequest({ ...request, pageSize })}
                        page={request.page}
                    />
                )}
            </div>
            {isModalOpen && (
                <CodebookDetailsModal
                    isOpen={isModalOpen}
                    handleClose={() => setIsModalOpen(false)}
                    codebookType={getCodeBooktype(menuItem?.label ?? "")}
                />
            )}
        </div>
    );
};
