import { NotificationSubscriptionCategory } from "modules/common/constants/notificationSubscriptions";
import { convertSubscriptionsToValues } from "modules/common/utils/notificationSubscriptionUtils";
import {
    NotificationSettings,
    NotificationSubscriptions,
    updateNotificationSubscription,
} from "modules/dashboard";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

interface Props {
    subscriptions: Array<{ values: number[]; category: NotificationSubscriptionCategory }>;
}

export const Settings: React.FC<Props> = ({ subscriptions }) => {
    const dispatch = useDispatch();
    const [activatedSubscriptions, setActivatedSubscriptions] = useState<string[]>([]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const splitValues = value.split("_");
        const category = splitValues[0] as NotificationSubscriptionCategory;
        const id = parseInt(splitValues[1]);
        dispatch(updateNotificationSubscription(category, id));

        const subscriptionKey = `${category}_${id}`;
        setActivatedSubscriptions(prev =>
            prev.includes(subscriptionKey)
                ? prev.filter(subscription => subscription !== subscriptionKey)
                : [...prev, subscriptionKey]
        );
    };

    useEffect(() => {
        const newSubscriptions = subscriptions.flatMap(({ category, values }) =>
            convertSubscriptionsToValues(values, category)
        );

        setActivatedSubscriptions(newSubscriptions);
    }, [subscriptions]);

    return (
        <>
            <section>
                <NotificationSettings
                    onChange={handleChange}
                    activatedSubscriptions={activatedSubscriptions}
                />
            </section>
            <section>
                <NotificationSubscriptions
                    onChange={handleChange}
                    activatedSubscriptions={activatedSubscriptions}
                />
            </section>
        </>
    );
};
