import {
    Loading,
    NoResults,
    OptionType,
    Pagination,
    RangeDatePicker,
    RoleType,
} from "modules/common";
import {
    DateRangeFilter,
    EducationFilters,
    EducationList,
    EducationSignupModal,
    getEducations,
    getEducationsRequest,
} from "modules/education";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { DateUtils, DayModifiers } from "react-day-picker";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ValueType } from "react-select";
import { sessionCache } from "modules/auth";
import { EducationItem } from "services";
import {
    getEducationsWithReminder,
    getEducationsWithRemindersRequest,
    getOperationTypes,
} from "../store";
import { isMinistryAdviser } from "modules/auth/authorization/roleChecks";
import { PanelMenu, PanelMenuItem } from "modules/common/components/PanelMenu";
import { EducationsRoutes } from "modules/common/constants/routes";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron-left.svg";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { add } from "date-fns";

const PAGE_NEIGHBOURS = 1;
const INPUT_DEBOUNCE_TIME = 500; //miliseconds
const isFarmer = (roleType: number) => RoleType.Farmer === roleType;

const DefaultPageSize = {
    value: "10",
    label: "10",
};

enum TabLabels {
    Available = "Dostupno",
    Reminders = "Podsjetnici",
    Listened = "Odslušano",
    Applied = "Prijavljeno",
}

const getMenuItems = (): PanelMenuItem[] => [
    {
        label: TabLabels.Available,
        link: EducationsRoutes.Available,
    },
    {
        label: TabLabels.Applied,
        link: EducationsRoutes.Applied,
    },
    {
        label: TabLabels.Listened,
        link: EducationsRoutes.Listened,
    },
    {
        label: TabLabels.Reminders,
        link: EducationsRoutes.Reminders,
    },
];

export const EducationSignup: React.FC = () => {
    const { profile } = useSelector((state: RootState) => state.profile);
    const user = sessionCache().getSession();
    const dispatch = useDispatch();
    const { educations, appliedEducations, listenedEducations, educationsWithReminders } =
        useSelector((state: RootState) => state.educations);
    const isLoading = useSelector(
        (state: RootState) =>
            state.educations.isLoadingEducations || state.educations.isLoadingReminders
    );
    const roleType = user?.roleType.value ?? profile?.roleType?.value;
    const menuItems = getMenuItems();
    const [selectedMenuItem, setSelectedMenuItem] = useState(
        menuItems?.find(item => !item.isDisabled)
    );

    const Filters = isFarmer(roleType ?? RoleType.Farmer)
        ? [TabLabels.Available, TabLabels.Applied, TabLabels.Listened, TabLabels.Reminders]
        : isMinistryAdviser(roleType ?? RoleType.MinistryAdviser)
        ? [TabLabels.Applied, TabLabels.Reminders]
        : ["Dostupne edukacije"];

    const [tabFilter, setTabFilter] = useState<number>(
        menuItems.indexOf(selectedMenuItem ?? menuItems[0])
    );
    const [countyFilter, setCountyFilter] = useState<number[]>([]);
    const [expertAreasFilter, setExpertAreasFilter] = useState<string[]>([]);
    const [isMandatoryFilter, setIsMandatoryFilter] = useState(false);
    const [isOupFilter, setIsOupFilter] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [date, setDate] = useState<DateRangeFilter>({
        from: new Date(),
        to: add(new Date(), { months: 3 }),
    });
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState<OptionType | null>(DefaultPageSize);
    const [semisId, setSemisId] = useState<number | undefined>();
    const [chosenEducations, setChosenEducations] = useState<{
        count: number;
        item: EducationItem[];
    }>();
    const [selectedEducation, setSelectedEducation] = useState<EducationItem | null>();
    const operationTypes = useSelector((state: RootState) => state.operationTypes.items);

    useEffect(() => {
        if (!operationTypes?.length) {
            dispatch(getOperationTypes());
        }
    }, [dispatch, operationTypes]);

    const changeSelectedMenuItem = useCallback(
        (menuItemToChangeTo: PanelMenuItem) => {
            setSelectedMenuItem(menuItemToChangeTo);
            const newTabFilter = menuItems.indexOf(menuItemToChangeTo);
            setTabFilter(newTabFilter);
        },
        [menuItems]
    );

    const handleSearch = (searchString: string) => {
        setSearchTerm(searchString);
        setCurrentPage(1);
    };

    const handleSelect = (itemName: string) => {
        setSearchTerm(itemName);
        setCurrentPage(1);
    };

    const handleDayClick = (
        day: Date,
        modifiers: DayModifiers,
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        const range = DateUtils.addDayToRange(day, date);
        setDate({ from: range.from ?? new Date(), to: range.to ?? new Date() });
        setCurrentPage(1);
    };

    const updateRange = (from: Date, to: Date) => {
        setDate({ from, to });
        setCurrentPage(1);
    };

    const handlePageSizeChange = (value: ValueType<OptionType, false>) => {
        setPageSize(value);
        setCurrentPage(1);
    };

    const handlePageClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const page = Number(parseInt(event.currentTarget.id));
        setCurrentPage(page);
    };

    const handleNexPageClick = () => {
        setCurrentPage(prev => prev + 1);
    };

    const handlePrevPageClick = () => {
        setCurrentPage(prev => prev - 1);
    };

    function toggleModal(id?: number) {
        setSemisId(id);
        setShowModal(!showModal);
    }

    const formattedOperationTypes = useMemo(() => {
        return operationTypes.map((type, index) => ({ id: index, name: type }));
    }, [operationTypes]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(
            (
                currentPage: number,
                size: number,
                searchTerm: string | null,
                counties: number[],
                expertAreas: string[],
                dateFrom: Date | null,
                dateTo: Date | null,
                tabFilter: number,
                isMandatory: boolean,
                isOup: boolean
            ) => {
                dispatch(
                    getEducations({
                        pageNumber: currentPage,
                        pageSize: size,
                        title: searchTerm,
                        counties: counties,
                        expertAreas,
                        dateFrom: dateFrom,
                        dateTo: dateTo,
                        status: tabFilter === 0 ? null : tabFilter,
                        isMandatory,
                        isOup,
                    })
                );
                dispatch(getEducationsWithReminder({ pageNumber: currentPage, pageSize: size }));
            },
            INPUT_DEBOUNCE_TIME
        ),
        []
    );

    useEffect(() => {
        const size = parseInt(pageSize?.value ?? DefaultPageSize.value);
        // api call is debounced, switch to loading earlier
        if (menuItems[tabFilter]?.label !== TabLabels.Reminders) {
            dispatch(getEducationsRequest());
            debouncedSearch(
                currentPage,
                size,
                searchTerm,
                countyFilter,
                expertAreasFilter,
                date.from,
                date.to,
                tabFilter,
                isMandatoryFilter,
                isOupFilter
            );
        }
    }, [
        currentPage,
        debouncedSearch,
        dispatch,
        pageSize?.value,
        searchTerm,
        countyFilter,
        expertAreasFilter,
        date,
        tabFilter,
        isMandatoryFilter,
        isOupFilter,
    ]);

    useEffect(() => {
        setChosenEducations(undefined);
    }, [tabFilter]);

    useEffect(() => {
        if (!educations && !appliedEducations && !listenedEducations && !educationsWithReminders) {
            setChosenEducations(undefined);
            return;
        }

        if (!tabFilter && educations) {
            setChosenEducations({
                count: educations.count,
                item: educations.item,
            });
            return;
        }

        if (menuItems[tabFilter]?.label === TabLabels.Applied && appliedEducations) {
            setChosenEducations({
                count: appliedEducations.count,
                item: appliedEducations.item,
            });
            return;
        }

        if (menuItems[tabFilter]?.label === TabLabels.Listened && listenedEducations) {
            setChosenEducations({
                count: listenedEducations.count,
                item: listenedEducations.item,
            });
            return;
        }

        if (menuItems[tabFilter]?.label === TabLabels.Reminders && educationsWithReminders) {
            setChosenEducations({
                count: educationsWithReminders.count,
                item: educationsWithReminders.items.map(e => e.education),
            });
            return;
        }
    }, [educations, appliedEducations, listenedEducations, educationsWithReminders, tabFilter]);

    useEffect(() => {
        setPageSize(DefaultPageSize);
        setCurrentPage(1);
        setSelectedEducation(null);
        setChosenEducations(undefined);
    }, [tabFilter]);

    return (
        <>
            {selectedEducation && (
                <div className="education-details-return-banner">
                    <div
                        className="education-return-button"
                        onClick={() => setSelectedEducation(null)}
                    >
                        <ChevronLeftIcon />
                    </div>
                </div>
            )}
            <div className="educations-layout">
                <div className="educations-left">
                    {Filters[tabFilter] !== "Podsjetnici" && !selectedEducation && (
                        <div className="f">
                            <ReactSearchAutocomplete
                                items={formattedOperationTypes}
                                onSearch={handleSearch}
                                onSelect={i => handleSelect(i.name)}
                                placeholder="Pretraži"
                                className="autocomplete"
                                showNoResults={false}
                            />
                        </div>
                    )}
                    <PanelMenu
                        items={menuItems}
                        selectedItem={selectedMenuItem}
                        onItemClicked={item => {
                            changeSelectedMenuItem(item);
                        }}
                    />
                </div>
                <div className="educations-right">
                    {!selectedEducation && (
                        <div className="educations-container-heading">
                            {Filters[tabFilter] !== "Podsjetnici" && (
                                <>
                                    <div className="educations__filters">
                                        <div className="educations-container-filters">
                                            <span>TEČAJEVI</span>
                                            <EducationFilters
                                                countyFilter={countyFilter}
                                                setCountyFilter={setCountyFilter}
                                                expertAreasFilter={expertAreasFilter}
                                                setExpertAreasFilter={setExpertAreasFilter}
                                                isMandatoryFilter={isMandatoryFilter}
                                                setIsMandatoryFilter={setIsMandatoryFilter}
                                                isOupFilter={isOupFilter}
                                                setIsOupFilter={setIsOupFilter}
                                                setCurrentPage={setCurrentPage}
                                                setDate={setDate}
                                                date={date}
                                            />
                                        </div>
                                    </div>
                                    <div className="educations-date">
                                        <RangeDatePicker
                                            onClick={handleDayClick}
                                            selectedDays={date}
                                            modifiers={{ start: date.from, end: date.to }}
                                            updateRange={updateRange}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {!isLoading ? (
                        chosenEducations && chosenEducations.item.length ? (
                            <>
                                <EducationList
                                    items={chosenEducations.item}
                                    onClick={toggleModal}
                                    filter={tabFilter}
                                    selectedEducation={selectedEducation}
                                    setSelectedEducation={setSelectedEducation}
                                    currentTab={menuItems[tabFilter]?.label}
                                />
                                {!selectedEducation && (
                                    <Pagination
                                        currentPage={currentPage}
                                        numberOfPages={Math.ceil(
                                            chosenEducations.count /
                                                parseInt(pageSize?.value ?? DefaultPageSize.value)
                                        )}
                                        pageNeighbours={PAGE_NEIGHBOURS}
                                        pageSize={pageSize ?? DefaultPageSize}
                                        onPageSizeChange={handlePageSizeChange}
                                        handlepageClick={handlePageClick}
                                        next={handleNexPageClick}
                                        prev={handlePrevPageClick}
                                    />
                                )}
                            </>
                        ) : (
                            <NoResults className="mt-24" />
                        )
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
            <EducationSignupModal
                toggleModal={toggleModal}
                isVisible={showModal}
                semisId={semisId}
            />
        </>
    );
};
