import React, { useEffect, useRef, useState } from "react";
import { CountyFilter, FilterGroup, RoleFilter, useOutsideClick } from "modules/common";
import { StatusFilter } from "modules/common/components/StatusFilter";
import {
    useGetFilterPreferencesQuery,
    useUpdateFilterPreferences,
} from "modules/common/hooks/filterPreferencesHooks";
import { ReactComponent as FilterClearIcon } from "assets/icons/filter-clear.svg";

interface Props {
    roleFilter: number[];
    countyFilter: number[];
    statusFilter: number;
    setCountyFilter: React.Dispatch<React.SetStateAction<number[]>>;
    setRoleFilter: (value: React.SetStateAction<number[]>) => void;
    setStatusFilter: (value: React.SetStateAction<number>) => void;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const AdministrationFilters: React.FC<Props> = ({
    roleFilter,
    countyFilter,
    statusFilter,
    setCountyFilter,
    setRoleFilter,
    setStatusFilter,
    setCurrentPage,
}) => {
    const filterPreferencesQuery = useGetFilterPreferencesQuery([
        `${AdministrationFilters.name}-roleFilter`,
        `${AdministrationFilters.name}-countyFilter`,
        `${AdministrationFilters.name}-statusFilter`,
    ]);

    const updateFilterPreferencesMutation = useUpdateFilterPreferences();

    const [showCountyFilter, setShowCountyFilter] = useState(false);
    const [showRoleFilter, setShowRoleFilter] = useState(false);
    const [showStatusFilter, setShowStatusFilter] = useState(false);
    const countyFilterRef = useRef<HTMLDivElement>(null);
    const roleFilterRef = useRef<HTMLDivElement>(null);
    const statusFilterRef = useRef<HTMLDivElement>(null);

    const isRoleFilterActive = roleFilter.length > 0;
    const isCountryFilterActive = countyFilter.length > 0;
    const isStatusFilterActive = statusFilter > -1;

    const handleCountyFilterOutsideClick = () => {
        setShowCountyFilter(false);
    };

    const handleRoleFilterOutsideClick = () => {
        setShowRoleFilter(false);
    };

    const handleStatusFilterOutsideClick = () => {
        setShowStatusFilter(false);
    };

    useOutsideClick(countyFilterRef, handleCountyFilterOutsideClick);
    useOutsideClick(roleFilterRef, handleRoleFilterOutsideClick);
    useOutsideClick(statusFilterRef, handleStatusFilterOutsideClick);

    const handleRoleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const valueNumb = parseInt(value);
        setRoleFilter(prev => {
            if (!prev.includes(valueNumb)) {
                return [...prev, valueNumb];
            } else {
                return [...prev.filter(s => s !== valueNumb)];
            }
        });
        handleUpdateFilterPreferences(`${AdministrationFilters.name}-roleFilter`, valueNumb);
    };

    const handleCountyFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const valueNumb = parseInt(value);
        setCountyFilter(prev => {
            if (!prev.includes(valueNumb)) {
                return [...prev, valueNumb];
            } else {
                return [...prev.filter(s => s !== valueNumb)];
            }
        });
        handleUpdateFilterPreferences(`${AdministrationFilters.name}-countyFilter`, valueNumb);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setStatusFilter(parseInt(value));
        handleUpdateFilterPreferences(
            `${AdministrationFilters.name}-statusFilter`,
            parseInt(value)
        );
    };

    const handleRoleFilterClear = () => {
        handleUpdateFilterPreferences(`${AdministrationFilters.name}-roleFilter`, undefined, true);
    };

    const handleCountyFilterClear = () => {
        handleUpdateFilterPreferences(
            `${AdministrationFilters.name}-countyFilter`,
            undefined,
            true
        );
    };

    const handleStatusFilterClear = () => {
        handleUpdateFilterPreferences(
            `${AdministrationFilters.name}-statusFilter`,
            undefined,
            true
        );
    };

    const handleRoleFilterClick = () => {
        setShowRoleFilter(prev => !prev);
    };

    const handleCountyFilterClick = () => {
        setShowCountyFilter(prev => !prev);
    };

    const handleStatusFilterClick = () => {
        setShowStatusFilter(prev => !prev);
    };

    const handleUpdateFilterPreferences = (
        filterName: string,
        filterValue?: number,
        clearFilter?: boolean
    ) => {
        updateFilterPreferencesMutation.mutate({
            filterName,
            filterIntValue: filterValue,
            clearFilter,
        });
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        handleRoleFilterClear();
        handleCountyFilterClear();
        handleStatusFilterClear();
    };

    useEffect(() => {
        if (filterPreferencesQuery.some(s => s.isLoading)) {
            return;
        }

        const roleFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${AdministrationFilters.name}-roleFilter`
        )?.data?.filterIntValues;

        const countyFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${AdministrationFilters.name}-countyFilter`
        )?.data?.filterIntValues;

        const statusFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${AdministrationFilters.name}-statusFilter`
        )?.data?.filterIntValues;

        if (roleFilterData) {
            setRoleFilter(roleFilterData);
        }

        if (countyFilterData) {
            setCountyFilter(countyFilterData);
        }

        if (statusFilterData) {
            setStatusFilter(statusFilterData[0]);
        }
    }, [filterPreferencesQuery]);

    return (
        <FilterGroup text="">
            <div className="mr-2" ref={roleFilterRef}>
                <RoleFilter
                    onChange={handleRoleFilterChange}
                    onClear={handleRoleFilterClear}
                    onClick={handleRoleFilterClick}
                    value={roleFilter}
                    isActive={isRoleFilterActive}
                    showDropdown={showRoleFilter}
                />
            </div>
            <div className="mr-2" ref={countyFilterRef}>
                <CountyFilter
                    onChange={handleCountyFilterChange}
                    onClear={handleCountyFilterClear}
                    onClick={handleCountyFilterClick}
                    value={countyFilter}
                    isActive={isCountryFilterActive}
                    showDropdown={showCountyFilter}
                />
            </div>
            <div className="mr-2" ref={statusFilterRef}>
                <StatusFilter
                    onChange={handleStatusFilterChange}
                    onClear={handleStatusFilterClear}
                    onClick={handleStatusFilterClick}
                    value={statusFilter}
                    isActive={isStatusFilterActive}
                    showDropdown={showStatusFilter}
                />
            </div>
            {(roleFilter.length > 0 || countyFilter.length > 0 || statusFilter > -1) && (
                <FilterClearIcon
                    className="filter-group__icon clear-filter ml-16"
                    onClick={handleClearFilter}
                />
            )}
        </FilterGroup>
    );
};
