import * as React from "react";
import { ToggleSwitch } from "modules/common";
import TitleBar from "modules/common/components/TitleBar/TitleBar";
import { NotificationSettingsSection } from "modules/common/constants/notificationSubscriptions";
import { isSubscriptionActive } from "modules/common/utils/notificationSubscriptionUtils";

interface Props {
    activatedSubscriptions: string[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NotificationSettings: React.FC<Props> = ({ activatedSubscriptions, onChange }) => {
    if (!activatedSubscriptions) {
        return null;
    }
    return (
        <div className="notifications-container">
            <TitleBar>
                <TitleBar.Left>
                    <span className="title-bar-text profile-title">Način obavještavanja</span>
                </TitleBar.Left>
                <TitleBar.Right>
                    <span className="title-bar-description">
                        Ukoliko isključite sve opcije nećete moći pravovremeno primati obavijesti o
                        novostima i vašim obavezama.
                    </span>
                </TitleBar.Right>
            </TitleBar>
            <span className="notifications__description">
                Budite u toku sa svim novostima i obavezama.
            </span>
            <div className="notifications">
                {NotificationSettingsSection.map(section =>
                    section.subscriptions.map(item => (
                        <div key={item.id} className="notification-row">
                            <div>
                                <label htmlFor={item.title} className="notification-row__label">
                                    {item.title}
                                </label>
                            </div>
                            <ToggleSwitch
                                htmlFor={item.title}
                                value={`${item.category}_${item.id}`}
                                onChange={onChange}
                                checked={isSubscriptionActive(
                                    activatedSubscriptions,
                                    item.category,
                                    item.id
                                )}
                                className="notification-row__toggle"
                            />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
