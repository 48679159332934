//TODO: Commented are not part of release 1.5
export enum AppRoutes {
    Home = "/",
    SignIn = "/prijava",
    SignInAd = "/prijava-active-directory",
    SignUp = "/registracija",
    ForgotPassword = "/zaboravljena-lozinka",
    ChangePassword = "/nova-lozinka",
    ActivateAccount = "/aktivacija-racuna",
    Education = "/tecajevi",
    News = "/vijesti",
    AgroclimateStations = "/unauthorized", //agroklimatske-stanice
    Agrometeorology = "/agrometeorologija",
    Records = "/evidencije",
    PlantProtection = "/zastita-bilja",
    UserActivity = "/aktivnost-korisnika",
    UserAdministration = "/administracija-korisnika",
    Profile = "/profil",
    SystemAdministration = "/unauthorized", //administracija-sustava
    NotAuthorized = "/unauthorized",
    Presentations = "/", //prezentacije
    Communication = "/unauthorized", //komunikacija-s-korisnicima
    ModulesAdministration = "/administracija-modula",
    ModuleVersionsAdministration = "/administracija-verzija-modula",
    ExternalModules = "/vanjski-moduli",
    SemisAdministrationModule = "/semis-administracija",
    EducationAnalysis = "/analiza-edukacija",
    Codebook = "/sifrarnik",
}
