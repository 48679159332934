import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Navigation } from "../Navigation";
import { ReactComponent as HamburgerIcon } from "assets/icons/hamburger.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron-left.svg";
import { ProfileSettings } from "../ProfileSettings";
import { AppRoutes } from "routing";
import { useSelector } from "react-redux";
import { RootState } from "store";
import LogoEAdviser from "../../../../assets/images/LogoESavjetnik.svg";
import { Input } from "../Input";
import Icon from "../../../../assets/icons/search.svg";

interface Props {
    setMenuOpen: () => void;
}
export const MainHeader: React.FC<Props> = ({ setMenuOpen }) => {
    const isUserLoggedIn = useSelector((state: RootState) => state.signIn?.signInSuccess);

    const history = useHistory();

    return (
        <header className="header-container container-x">
            <div className="header-hamburger">
                <HamburgerIcon onClick={setMenuOpen} />
            </div>
            <div className={`round-button circle header`} onClick={() => history.goBack()}>
                <ChevronLeftIcon className="day-picker-control" />
            </div>
            <Link to={AppRoutes.Home}>
                <img src={LogoEAdviser} alt="Logo eSavjetnik" className="header-logo" />
            </Link>
            <div className="header-navigation">
                <Input
                    size="small"
                    name="username"
                    className="input-class"
                    id="user-id"
                    type="text"
                    isValid={true}
                    placeholder=""
                    errorMessage="Invalid input"
                    rightIcon={<img src={Icon} alt="search icon" className="search-icon" />}
                    //funkcionalnost ovoga gumba će doći kasnije
                    //value={null}
                    flexField={true}
                />
                <Navigation />
            </div>
            {isUserLoggedIn ? (
                <div className="header-profile">
                    <ProfileSettings />
                </div>
            ) : (
                <Link className="header-login-button" to={AppRoutes.SignIn}>
                    Prijava
                </Link>
            )}
        </header>
    );
};
