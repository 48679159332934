import * as React from "react";
import { ToggleSwitch } from "modules/common";
import TitleBar from "modules/common/components/TitleBar/TitleBar";
import {
    NotificationSubscriptionsSection,
    ProfileSubscriptionSection,
    Subscription,
} from "modules/common/constants/notificationSubscriptions";
import { isSubscriptionActive } from "modules/common/utils/notificationSubscriptionUtils";
import { Accordion } from "modules/common/components/Accordion";

interface Props {
    activatedSubscriptions?: string[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NotificationSubscriptions: React.FC<Props> = ({
    activatedSubscriptions,
    onChange,
}) => {
    if (!activatedSubscriptions) {
        return null;
    }

    const renderSubscriptionRow = (item: Subscription) => (
        <div key={`${item.category}_${item.id}`} className="subscription-row">
            <div>
                <label htmlFor={`${item.category}_${item.id}`} className="subscription-row__label">
                    {item.title}
                </label>
            </div>
            <ToggleSwitch
                htmlFor={`${item.category}_${item.id}`}
                value={`${item.category}_${item.id}`}
                onChange={onChange}
                checked={isSubscriptionActive(activatedSubscriptions, item.category, item.id)}
                className="subscription-row__toggle"
            />
        </div>
    );

    const renderSubgroup = (subgroup: ProfileSubscriptionSection) => (
        <div key={subgroup.groupTitle}>
            <Accordion title={subgroup.groupTitle!} className="subgroup-accordion">
                <div className="subscriptions">
                    {subgroup.subscriptions.map(renderSubscriptionRow)}
                </div>
            </Accordion>
        </div>
    );

    const renderGroupContent = (section: ProfileSubscriptionSection) => (
        <div className="subscriptions" key={section.groupTitle}>
            {section.subscriptions.map(renderSubscriptionRow)}
            {section.subgroups?.map(renderSubgroup)}
        </div>
    );

    const renderGroup = (section: ProfileSubscriptionSection, isLast: boolean) => (
        <div key={section.groupTitle}>
            <Accordion
                title={section.groupTitle ?? ""}
                className="subscriptions-accordion"
                buttonWithBorder
            >
                {renderGroupContent(section)}
            </Accordion>
            {!isLast ? (
                <div className="section-separator" />
            ) : (
                <div className="section-spacer"></div>
            )}
        </div>
    );

    const renderRegularSection = (section: ProfileSubscriptionSection) => (
        <div className="subscriptions" key={section.subscriptions[0].id}>
            {section.subscriptions.map(renderSubscriptionRow)}
        </div>
    );

    return (
        <div>
            <TitleBar>
                <TitleBar.Left>
                    <span className="title-bar-text profile-title">Predbilježbe</span>
                </TitleBar.Left>
            </TitleBar>
            <span className="subscriptions__description">
                Primajte pravovremene obavijesti iz određenih kategorija ovisno o vašim interesima.
            </span>
            {NotificationSubscriptionsSection.map((section, index) =>
                section.groupTitle
                    ? renderGroup(section, index === NotificationSubscriptionsSection.length - 1)
                    : renderRegularSection(section)
            )}
        </div>
    );
};
