import { NotificationSubscriptionCategory } from "../constants/notificationSubscriptions";

export function convertSubscriptionsToValues(
    subscriptions: number[],
    category: NotificationSubscriptionCategory
) {
    return subscriptions.map(subscription => {
        return `${category}_${subscription}`;
    });
}

export function isSubscriptionActive(
    subscriptions: string[],
    category: NotificationSubscriptionCategory,
    value: number
) {
    return subscriptions.includes(`${category}_${value}`);
}
