import { formErrorMessages, isValidMobile } from "modules/auth";
import { Button, Input, ProfileInfo, RoleType } from "modules/common";
import { updateMobileNumber } from "modules/dashboard";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

const isFarmer = (roleType: number) => RoleType.Farmer === roleType;
const isCitizen = (roleType: number) => RoleType.Citizen === roleType;
const isPrivateAdviser = (roleType: number) => RoleType.PrivateAdviser === roleType;

export const ProfileInfoForm: React.FC = () => {
    const dispatch = useDispatch();
    const { profile } = useSelector((state: RootState) => state.profile);

    const [state, setState] = useState<{
        mobileNumber: string | undefined;
        additionalMobileNumber: string | undefined;
    }>({
        mobileNumber: undefined,
        additionalMobileNumber: undefined,
    });
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const roleType = profile?.roleType?.value;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;

        setState(prev => ({
            ...prev,
            [name]: value,
        }));

        if (value && isValidMobile(value)) {
            clearFormError(name);
        }
    };

    const clearFormError = (field: string) => {
        setFormErrors(prev => {
            const { [field]: _, ...remainingErrors } = prev;
            return remainingErrors;
        });
    };

    const handlePhoneBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { value, name } = event.target;

        if (!value.trim()) {
            clearFormError(name);
            return;
        }

        if (!isValidMobile(value)) {
            setFormErrors(prev => ({
                ...prev,
                [name]: formErrorMessages.invalidPhone,
            }));
        } else {
            clearFormError(name);
        }
    };

    function onSubmit() {
        if (
            state.mobileNumber !== profile?.mobileNumber ||
            (state.additionalMobileNumber !== profile?.additionalMobileNumber &&
                (!formErrors["mobileNumber"] || !formErrors["additionalMobileNumber"]))
        ) {
            dispatch(
                updateMobileNumber(
                    state.mobileNumber ?? profile?.mobileNumber ?? "",
                    state.additionalMobileNumber ?? profile?.additionalMobileNumber ?? ""
                )
            );
        }
    }

    return (
        <div className="profile-phone-column">
            {!isFarmer(roleType ?? RoleType.Farmer) &&
                !isCitizen(roleType ?? RoleType.Citizen) &&
                !isPrivateAdviser(roleType ?? RoleType.PrivateAdviser) && (
                    <ProfileInfo>
                        <label className="profile-settings-label" htmlFor="phone">
                            SEMIS broj
                        </label>
                        <div className="profile-settings-input">
                            <Input
                                id="mobileNumber"
                                type="text"
                                size="large"
                                isValid={!formErrors["mobileNumber"]}
                                name="mobileNumber"
                                errorMessage={formErrors["mobileNumber"]}
                                onChange={handleInputChange}
                                onBlur={handlePhoneBlur}
                                value={state.mobileNumber ?? profile?.mobileNumber}
                            />
                        </div>
                    </ProfileInfo>
                )}
            {isFarmer(roleType ?? RoleType.Farmer) && (
                <ProfileInfo>
                    <label className="profile-settings-label" htmlFor="phone">
                        Moj kontakt
                    </label>
                    <div className="profile-settings-input">
                        <Input
                            id="additionalMobileNumber"
                            type="text"
                            size="large"
                            isValid={!formErrors["additionalMobileNumber"]}
                            name="additionalMobileNumber"
                            errorMessage={formErrors["additionalMobileNumber"]}
                            onChange={handleInputChange}
                            onBlur={handlePhoneBlur}
                            value={state.additionalMobileNumber ?? profile?.additionalMobileNumber}
                        />
                    </div>
                </ProfileInfo>
            )}
            <ProfileInfo>
                <div className="profile-settings-button">
                    <Button
                        type="submit"
                        variant="primary"
                        size="small"
                        isDisabled={Boolean(Object.keys(formErrors).length)}
                        onClick={onSubmit}
                    >
                        <span>Spremi izmjene</span>
                    </Button>
                </div>
            </ProfileInfo>
        </div>
    );
};
