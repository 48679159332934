import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DeactivateAccountModal, Settings } from "modules/dashboard";
import {
    Button,
    ProfileInfo,
    ProfileInfoCitizenForm,
    ProfileInfoForm,
    ProfileSkeleton,
    RoleType,
    useTitle,
} from "modules/common";
import { RootState } from "store";
import TitleBar from "modules/common/components/TitleBar/TitleBar";
import { NotificationSubscriptionCategory } from "modules/common/constants/notificationSubscriptions";

const isCitizen = (roleType: number) => RoleType.Citizen === roleType;
const isFarmer = (roleType: number) => RoleType.Farmer === roleType;

export const Profile: React.FC = () => {
    useTitle("Profil - eSavjetnik");
    const user = useSelector((state: RootState) => state.signIn?.user);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const { profile, isLoading, isUpdating } = useSelector((state: RootState) => state.profile);
    const roleType = user?.role?.value ?? profile?.roleType?.value;
    const activatedSubscriptions = [
        {
            category: NotificationSubscriptionCategory.InfoTypeSubscription,
            values: profile?.infoType ?? [],
        },
        {
            category: NotificationSubscriptionCategory.NotificationTypeSubscription,
            values: profile?.notificationType ?? [],
        },
        {
            category: NotificationSubscriptionCategory.PlantProtectionCountySubscription,
            values: profile?.plantProtectionCounties ?? [],
        },
        {
            category: NotificationSubscriptionCategory.NewsProfessionalFieldSubscription,
            values: profile?.infoProfessionalField ?? [],
        },
        {
            category: NotificationSubscriptionCategory.MainfestationsProfessionalFieldSubscription,
            values: profile?.manifestationsProfessionalField ?? [],
        },
        {
            category: NotificationSubscriptionCategory.ManifestationsCountySubscription,
            values: profile?.manifestationsCounties ?? [],
        },
    ];

    const toggleDeactivateModal = () => {
        setShowDeactivateModal(!showDeactivateModal);
    };

    return (
        <>
            {isLoading || isUpdating ? (
                <ProfileSkeleton />
            ) : (
                <div className="profile-container">
                    <div>
                        <TitleBar>
                            <TitleBar.Left>
                                <span className="title-bar-text profile-title">
                                    Tvoje informacije
                                </span>
                            </TitleBar.Left>
                            <TitleBar.Right>
                                <span className="title-bar-description">
                                    Prikazani podaci su povučeni iz drugog sustava i nije moguća
                                    promjena unutar e-Savjetnik sustava.
                                </span>
                            </TitleBar.Right>
                        </TitleBar>
                        <span className="profile-description">
                            Osnovne informacije o korisniku.
                        </span>
                        {isCitizen(roleType ?? RoleType.Citizen) ? (
                            <>
                                <ProfileInfo className="mt-48">
                                    <span className="profile-settings-label">Ime i prezime</span>
                                    <span className="profile-settings-text">
                                        {profile?.firstName} {profile?.lastName}
                                    </span>
                                    <span className="profile-settings-label">Uloga</span>
                                    <span className="profile-settings-text">
                                        {profile?.roleType?.label ?? ""}
                                    </span>
                                    <span className="profile-settings-label">E-mail</span>
                                    <span className="profile-settings-text">{profile?.email}</span>
                                    <span className="profile-settings-label">Mjesto</span>
                                    <span className="profile-settings-text">
                                        {profile?.settlement}
                                    </span>
                                    <span className="profile-settings-label">Poštanski broj</span>
                                    <span className="profile-settings-text">
                                        {profile?.postalNumber &&
                                            profile.postalNumber.length &&
                                            `${profile.postalNumber}`}
                                    </span>
                                    <span className="profile-settings-label">Adresa</span>
                                    <span className="profile-settings-text">
                                        {profile?.street} {profile?.streetNumber}
                                    </span>
                                    <span className="profile-settings-label">Županija</span>
                                    <span className="profile-settings-text">{profile?.county}</span>
                                </ProfileInfo>
                                <div className="section-separator" />
                                <ProfileInfoCitizenForm />
                            </>
                        ) : (
                            <>
                                <section>
                                    <ProfileInfo>
                                        <span className="profile-settings-label">
                                            Ime i prezime
                                        </span>
                                        <span className="profile-settings-text">
                                            {profile?.firstName} {profile?.lastName}
                                        </span>
                                        {isFarmer(roleType ?? RoleType.Farmer) ? (
                                            <>
                                                <span className="profile-settings-label">
                                                    MIBPG
                                                </span>
                                                <span className="profile-settings-text">
                                                    {profile?.mibpg}
                                                </span>
                                            </>
                                        ) : null}
                                        {profile?.legalEntityName ? (
                                            <>
                                                <span className="profile-settings-label">
                                                    Poslovni subjekt
                                                </span>
                                                <span className="profile-settings-text">
                                                    {profile?.legalEntityName}
                                                </span>
                                            </>
                                        ) : null}
                                        <span className="profile-settings-label">Uloga</span>
                                        <span className="profile-settings-text">
                                            {profile?.roleType?.label ?? ""}
                                        </span>
                                        <span className="profile-settings-label">E-mail</span>
                                        <span className="profile-settings-text">
                                            {profile?.email}
                                        </span>
                                        <span className="profile-settings-label">Mjesto</span>
                                        <span className="profile-settings-text">
                                            {profile?.settlement}
                                        </span>
                                        <span className="profile-settings-label">
                                            Poštanski broj
                                        </span>
                                        <span className="profile-settings-text">
                                            {profile?.postalNumber &&
                                                profile.postalNumber.length &&
                                                `${profile.postalNumber}`}
                                        </span>
                                        <span className="profile-settings-label">Adresa</span>
                                        <span className="profile-settings-text">
                                            {profile?.street} {profile?.streetNumber}
                                        </span>
                                        <span className="profile-settings-label">Županija</span>
                                        <span className="profile-settings-text">
                                            {profile?.county}
                                        </span>
                                    </ProfileInfo>
                                </section>
                                <div className="section-separator" />
                                <ProfileInfoForm />
                            </>
                        )}

                        <div className="section-separator" />
                        <Settings subscriptions={activatedSubscriptions} />

                        {profile?.isActive && (
                            <>
                                <div className="section-separator" />
                                <section>
                                    <TitleBar>
                                        <TitleBar.Left>
                                            <span className="title-bar-text profile-title">
                                                Deaktivacija računa
                                            </span>
                                        </TitleBar.Left>
                                        <TitleBar.Right>
                                            <span className="title-bar-description">
                                                Deaktivacijom računa gubite pravo pristupa
                                                e-Savjetnik sustavu.
                                            </span>
                                        </TitleBar.Right>
                                    </TitleBar>
                                    <Button
                                        className="deactivation-button"
                                        type="button"
                                        variant="warning-ghost"
                                        size="small"
                                        isDisabled={false}
                                        onClick={toggleDeactivateModal}
                                    >
                                        <span className="deactivation-text">
                                            Deaktivacija računa
                                        </span>
                                    </Button>
                                </section>
                            </>
                        )}
                    </div>
                </div>
            )}

            <DeactivateAccountModal
                toggleModal={toggleDeactivateModal}
                isVisible={showDeactivateModal}
                userUuId={profile?.uid ?? ""}
                userRole={profile?.roleType?.value ?? 0}
            />
        </>
    );
};
