import React, { useEffect, useRef, useState } from "react";
import { FilterGroup, useOutsideClick } from "modules/common";
import {
    useGetFilterPreferencesQuery,
    useUpdateFilterPreferences,
} from "modules/common/hooks/filterPreferencesHooks";
import { ReactComponent as FilterClearIcon } from "assets/icons/filter-clear.svg";
import { GenericSwitchFilter } from "../../common/components/GenericSwitchFilter/GenericSwitchFilter";
import { useGetProductionTypesAsItems, useGetProfessionalAreasAsItems } from "modules/codebooks";
import { DeadlineFilter } from "./DeadlineFilter";

interface Props {
    professionalAreaFilter: string[];
    setProfessionalAreaFilter: React.Dispatch<React.SetStateAction<string[]>>;
    productionTypeFilter: string[];
    setProductionTypeFilter: React.Dispatch<React.SetStateAction<string[]>>;
    deadlineFilter: number[];
    setDeadlineFilter: React.Dispatch<React.SetStateAction<number[]>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const RecordsFilters: React.FC<Props> = ({
    professionalAreaFilter,
    setProfessionalAreaFilter,
    productionTypeFilter,
    setProductionTypeFilter,
    deadlineFilter,
    setDeadlineFilter,
    setCurrentPage,
}) => {
    const filterPreferencesQuery = useGetFilterPreferencesQuery([
        `${RecordsFilters.name}-professionalAreaFilter`,
        `${RecordsFilters.name}-productionTypeFilter`,
        `${RecordsFilters.name}-deadlineFilter`,
    ]);

    const updateFilterPreferencesMutation = useUpdateFilterPreferences();

    const [showProfessionalAreaFilter, setShowProfessionalAreaFilter] = useState(false);
    const [showProductionTypeFilter, setShowProductionTypeFilter] = useState(false);
    const [showDeadlineFilter, setShowDeadlineFilter] = useState(false);

    const professionalAreaFilterRef = useRef<HTMLDivElement>(null);
    const productionTypeFilterRef = useRef<HTMLDivElement>(null);
    const deadlineFilterRef = useRef<HTMLDivElement>(null);

    const isProfessionalAreaFilterActive = professionalAreaFilter.length > 0;
    const isProductionTypeFilterActive = productionTypeFilter.length > 0;
    const isDeadlineFilterActive = deadlineFilter.length > 0;

    const handleProfessionalAreaFilterOutsideClick = () => {
        setShowProfessionalAreaFilter(false);
    };

    const handleProductionTypeFilterOutsideClick = () => {
        setShowProductionTypeFilter(false);
    };

    const handleDeadlineFilterOutsideClick = () => {
        setShowDeadlineFilter(false);
    };

    useOutsideClick(professionalAreaFilterRef, handleProfessionalAreaFilterOutsideClick);
    useOutsideClick(productionTypeFilterRef, handleProductionTypeFilterOutsideClick);
    useOutsideClick(deadlineFilterRef, handleDeadlineFilterOutsideClick);

    const handleProfessionalAreaFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setProfessionalAreaFilter(prev => {
            if (!prev.includes(value)) {
                return [...prev, value];
            } else {
                return [...prev.filter(s => s !== value)];
            }
        });
        // TODO - filter preferences are not implemented yet
        //handleUpdateFilterPreferences(`${RecordsFilters.name}-professionalAreaFilter`, value);
    };

    const handleProductionTypeFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setProductionTypeFilter(prev => {
            if (!prev.includes(value)) {
                return [...prev, value];
            } else {
                return [...prev.filter(s => s !== value)];
            }
        });
        // TODO - filter preferences are not implemented yet
        //handleUpdateFilterPreferences(`${RecordsFilters.name}-productionTypeFilter`, value);
    };

    const handleDeadlineFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setDeadlineFilter(prev => {
            if (!prev.includes(Number(value))) {
                return [...prev, Number(value)];
            } else {
                return [...prev.filter(s => s !== Number(value))];
            }
        });
        // TODO - filter preferences are not implemented yet
        //handleUpdateFilterPreferences(`${RecordsFilters.name}-deadlineFilter`, value);
    };

    const handleProfessionalAreaFilterClear = () => {
        handleUpdateFilterPreferences(`${RecordsFilters.name}-professionalAreaFilter`, -1);
        setProfessionalAreaFilter([]);
    };

    const handleProductionTypeFilterClear = () => {
        handleUpdateFilterPreferences(`${RecordsFilters.name}-productionTypeFilter`, -1);
        setProductionTypeFilter([]);
    };

    const handleDeadlineFilterClear = () => {
        handleUpdateFilterPreferences(`${RecordsFilters.name}-deadlineFilter`, -1);
        setDeadlineFilter([]);
    };

    const handleProfessionalAreaFilterClick = () => {
        setShowProfessionalAreaFilter(prev => !prev);
    };

    const handleProductionTypeFilterClick = () => {
        setShowProductionTypeFilter(prev => !prev);
    };

    const handleDeadlineFilterClick = () => {
        setShowDeadlineFilter(prev => !prev);
    };

    const handleUpdateFilterPreferences = (filterName: string, filterValue: number) => {
        updateFilterPreferencesMutation.mutate({
            filterName,
            filterIntValue: filterValue,
        });
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        handleProfessionalAreaFilterClear();
        handleProductionTypeFilterClear();
        handleDeadlineFilterClear();
    };

    useEffect(() => {
        if (filterPreferencesQuery.some(s => s.isLoading)) {
            return;
        }

        const professionalAreaFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${RecordsFilters.name}-professionalAreaFilter`
        )?.data?.filterIntValues;

        const productionTypeFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${RecordsFilters.name}-productionTypeFilter`
        )?.data?.filterIntValues;

        const deadlineFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${RecordsFilters.name}-deadlineFilter`
        )?.data?.filterIntValues;

        // TODO - filter preferences are not implemented yet
        /*         if (professionalAreaFilterData) {
            setProfessionalAreaFilter(professionalAreaFilterData);
        }

        if (productionTypeFilterData) {
            setProductionTypeFilter(productionTypeFilterData);
        }

        if (deadlineFilterData) {
            setDeadlineFilter(deadlineFilterData);
        } */
    }, [filterPreferencesQuery]);

    const professionalAreasAsItems = useGetProfessionalAreasAsItems();
    const productionTypesAsItems = useGetProductionTypesAsItems();

    return (
        <FilterGroup text="">
            <div className="mr-2" ref={professionalAreaFilterRef}>
                <GenericSwitchFilter
                    name="STRUČNO PODRUČJE"
                    onChange={handleProfessionalAreaFilterChange}
                    onClear={handleProfessionalAreaFilterClear}
                    onClick={handleProfessionalAreaFilterClick}
                    values={professionalAreaFilter}
                    isActive={isProfessionalAreaFilterActive}
                    showDropdown={showProfessionalAreaFilter}
                    list={professionalAreasAsItems.map(item => ({
                        key: item.id,
                        value: item.label,
                    }))}
                />
            </div>

            <div className="mr-2" ref={productionTypeFilterRef}>
                <GenericSwitchFilter
                    name="VRSTA PROIZVODNJE"
                    onChange={handleProductionTypeFilterChange}
                    onClear={handleProductionTypeFilterClear}
                    onClick={handleProductionTypeFilterClick}
                    values={productionTypeFilter}
                    isActive={isProductionTypeFilterActive}
                    showDropdown={showProductionTypeFilter}
                    list={productionTypesAsItems.map(item => ({
                        key: item.id,
                        value: item.label,
                    }))}
                />
            </div>

            <div className="mr-2" ref={deadlineFilterRef}>
                <DeadlineFilter
                    name="ROK ISPUNJAVANJA"
                    onChange={handleDeadlineFilterChange}
                    onClear={handleDeadlineFilterClear}
                    onClick={handleDeadlineFilterClick}
                    isActive={isDeadlineFilterActive}
                    showDropdown={showDeadlineFilter}
                    values={deadlineFilter}
                />
            </div>

            {(professionalAreaFilter.length > 0 ||
                productionTypeFilter.length > 0 ||
                deadlineFilter.length > 0) && (
                <FilterClearIcon
                    className="filter-group__icon clear-filter ml-16"
                    onClick={handleClearFilter}
                />
            )}
        </FilterGroup>
    );
};
