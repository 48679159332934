import { Button, DisplayOrInput, Input, Modal, ModalFooter, ModalHeader } from "modules/common";
import { useEffect, useMemo, useState } from "react";
import { RecordCategory } from "services/records";
import { useCreateRecordCategory, useEditRecordCategory } from "../hooks/recordsHooks";

export interface IRecordCategoryModal {
    recordCategory?: RecordCategory;
    isOpen: boolean;
    handleClose: () => void;
}

const emptyRecordCategory: RecordCategory = {
    guid: "",
    name: "",
    createdBy: "",
    created: "",
    lastUpdatedBy: "",
    lastUpdated: "",
};

type RecordProperty = keyof RecordCategory;

type RecordCategoryErrors = Partial<Record<RecordProperty, string>>;

const validateRecord = (recordCategory: RecordCategory): RecordCategoryErrors => {
    const errors: RecordCategoryErrors = {};
    if (!recordCategory.name) {
        errors.name = "Naziv kategorije evidencije je obavezan";
    }

    return errors;
};

export const RecordCategoryModal = ({
    recordCategory: selectedRecordCategory,
    isOpen,
    handleClose,
}: IRecordCategoryModal) => {
    const [recordCategory, setRecordCategory] = useState<RecordCategory>(emptyRecordCategory);
    const [errors, setErrors] = useState<RecordCategoryErrors>({});
    const [isEdit, setIsEdit] = useState(false);
    const create = useCreateRecordCategory();
    const edit = useEditRecordCategory();

    useEffect(() => {
        setRecordCategory(selectedRecordCategory ?? emptyRecordCategory);
    }, [selectedRecordCategory, setRecordCategory]);

    useEffect(() => {
        setErrors({});
        setIsEdit(false);
    }, [isOpen, setErrors, setIsEdit]);

    const isAdd = useMemo(() => !selectedRecordCategory, [selectedRecordCategory]);

    const title = useMemo(() => {
        if (isAdd) {
            return "Nova kategorija evidencije";
        }
        if (isEdit) {
            return "Uredi kategoriju evidencije";
        }
        return recordCategory?.name || "Detalji kategorije evidencije";
    }, [isAdd, isEdit, recordCategory?.name]);

    const changeRecordProperty = (
        property: RecordProperty,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setErrors(prevState => ({ ...prevState, [property]: undefined }));
        setRecordCategory(prevState => ({
            ...(prevState ?? emptyRecordCategory),
            [property]: event.target.value,
        }));
    };

    const isValid = (property: RecordProperty) => {
        return !errors[property];
    };

    const getErrorMsg = (property: RecordProperty) => {
        return errors[property] ?? "";
    };

    const onCancel = () => {
        setIsEdit(false);
        setErrors({});
        const record = selectedRecordCategory ?? emptyRecordCategory;
        setRecordCategory(record);
    };

    const onSave = async () => {
        const newErrors = validateRecord(recordCategory);
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }

        const mutation = isAdd ? create.mutateAsync : edit.mutateAsync;
        await mutation(recordCategory);
        handleClose();
    };

    return (
        <Modal
            isActive={isOpen}
            className="record-category-modal"
            onClose={handleClose}
            modalHeader={<ModalHeader onClose={handleClose} title={title} />}
            modalFooter={
                !recordCategory ? null : (
                    <ModalFooter>
                        {isAdd || isEdit ? (
                            <>
                                {isEdit && (
                                    <Button
                                        type="button"
                                        size="small"
                                        variant="primary-ghost"
                                        isDisabled={false}
                                        onClick={onCancel}
                                    >
                                        <span>ODUSTANI</span>
                                    </Button>
                                )}
                                <Button
                                    type="button"
                                    size="small"
                                    variant="primary"
                                    isDisabled={false}
                                    onClick={onSave}
                                >
                                    <span>SPREMI</span>
                                </Button>
                            </>
                        ) : (
                            <Button
                                type="button"
                                size="small"
                                variant="primary-ghost"
                                isDisabled={false}
                                onClick={() => setIsEdit(true)}
                            >
                                <span>UREDI</span>
                            </Button>
                        )}
                    </ModalFooter>
                )
            }
        >
            <div className="record-category">
                <div className="modal-section">
                    <span className="record-category-label">Naziv kategorije evidencije</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {recordCategory?.name}
                            <Input
                                id="name"
                                type="text"
                                size="small"
                                name="name"
                                isValid={isValid("name")}
                                value={recordCategory?.name}
                                errorMessage={getErrorMsg("name")}
                                onChange={changeRecordProperty.bind(null, "name")}
                            />
                        </DisplayOrInput>
                    </span>
                </div>
            </div>
        </Modal>
    );
};
