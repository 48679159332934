import { Button, DisplayOrInput, Input, Modal, ModalFooter, ModalHeader } from "modules/common";
import { useEffect, useMemo, useState } from "react";
import { CodebookEntry } from "services/codebooks";
import {
    useCreateCodebookEntry,
    useEditCodebookEntry,
    useGetProfessionalAreasAsItems,
} from "../hooks/codebooksHooks";
import { CodebookType } from "modules/common/constants/codebookType";
import SearchableDropdown from "modules/common/components/SearchableDropdown/SearchableDropdown";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

export interface ICodebookEntryDetailsModal {
    codebook?: CodebookEntry;
    isOpen: boolean;
    handleClose: () => void;
    codebookType: CodebookType;
}

const emptyCodebook: CodebookEntry = {
    id: "",
    guid: "",
    name: "",
    code: "",
    targetGroup: "",
    professionalAreaGuid: "",
    professionalAreaName: "",
    created: "",
    createdBy: "",
    lastUpdated: "",
    lastUpdatedBy: "",
    codebookType: CodebookType.Unknown,
    isActive: true,
};

type CodebookEntryProperty = keyof CodebookEntry;

type CodebookEntryErrors = Partial<Record<CodebookEntryProperty, string>>;

const validateCodebook = (codebook: CodebookEntry): CodebookEntryErrors => {
    const errors: CodebookEntryErrors = {};
    if (!codebook.name) {
        errors.name = "Naziv je obavezan";
    }
    if (codebook.codebookType === CodebookType.Intervention && !codebook.code) {
        errors.code = "Kod je obavezan";
    }
    if (codebook.codebookType === CodebookType.Intervention && !codebook.targetGroup) {
        errors.targetGroup = "Ciljna grupa je obavezna";
    }
    if (codebook.codebookType === CodebookType.ProductionType && !codebook.professionalAreaGuid) {
        errors.professionalAreaGuid = "Stručno područje je obavezno";
    }
    return errors;
};

export const CodebookDetailsModal = ({
    codebook: selectedCodebook,
    isOpen,
    handleClose,
    codebookType,
}: ICodebookEntryDetailsModal) => {
    const [codebook, setCodebook] = useState<CodebookEntry>(emptyCodebook);
    const [errors, setErrors] = useState<CodebookEntryErrors>({});
    const [isEdit, setIsEdit] = useState(false);
    const create = useCreateCodebookEntry();
    const edit = useEditCodebookEntry();

    const professionalAreasAsItems = useGetProfessionalAreasAsItems();

    useEffect(() => {
        setCodebook(
            selectedCodebook
                ? { ...selectedCodebook, codebookType }
                : { ...emptyCodebook, codebookType }
        );
    }, [selectedCodebook, setCodebook, codebookType]);

    useEffect(() => {
        setErrors({});
        setIsEdit(false);
        if (!isOpen) {
            setCodebook({ ...emptyCodebook, codebookType });
        }
    }, [isOpen, setErrors, setIsEdit]);

    const isAdd = useMemo(() => !selectedCodebook, [selectedCodebook]);

    const title = useMemo(() => {
        if (isAdd) {
            return "Novi šifrarnik";
        }
        if (isEdit) {
            return "Uredi šifrarnik";
        }
        return codebook?.name || "Detalji šifrarnika";
    }, [isAdd, isEdit, codebook?.name]);

    const changeCodebookProperty = (
        property: CodebookEntryProperty,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setErrors(prevState => ({ ...prevState, [property]: undefined }));
        setCodebook(prevState => ({
            ...(prevState ?? emptyCodebook),
            [property]: event.target.value,
        }));
    };

    const isValid = (property: CodebookEntryProperty) => {
        return !errors[property];
    };

    const getErrorMsg = (property: CodebookEntryProperty) => {
        return errors[property] ?? "";
    };

    const onCancel = () => {
        setIsEdit(false);
        setErrors({});
        const codebook = selectedCodebook ?? emptyCodebook;
        setCodebook(codebook);
    };

    const onSave = async () => {
        const newErrors = validateCodebook(codebook);
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }
        const mutation = isAdd ? create.mutateAsync : edit.mutateAsync;
        await mutation(codebook);
        handleClose();
    };

    return (
        <Modal
            isActive={isOpen}
            className="codebook-details-modal"
            onClose={handleClose}
            modalHeader={<ModalHeader onClose={handleClose} title={title} />}
            modalFooter={
                !codebook ? null : (
                    <ModalFooter>
                        {isAdd || isEdit ? (
                            <>
                                {isEdit && (
                                    <Button
                                        type="button"
                                        size="small"
                                        variant="primary-ghost"
                                        isDisabled={false}
                                        onClick={onCancel}
                                    >
                                        <span>ODUSTANI</span>
                                    </Button>
                                )}
                                <Button
                                    type="button"
                                    size="small"
                                    variant="primary"
                                    isDisabled={false}
                                    onClick={onSave}
                                >
                                    <span>SPREMI</span>
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    type="button"
                                    size="small"
                                    variant="primary-ghost"
                                    isDisabled={false}
                                    onClick={() => setIsEdit(true)}
                                >
                                    <span>UREDI</span>
                                </Button>
                            </>
                        )}
                    </ModalFooter>
                )
            }
        >
            <div className="codebook-details">
                <div className="modal-section">
                    <span className="modal-details-label">Naziv</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {codebook?.name}
                            <Input
                                id="name"
                                type="text"
                                size="small"
                                name="name"
                                isValid={isValid("name")}
                                value={codebook?.name}
                                errorMessage={getErrorMsg("name")}
                                onChange={changeCodebookProperty.bind(null, "name")}
                            />
                        </DisplayOrInput>
                    </span>
                </div>
                {codebookType === CodebookType.Intervention && (
                    <div className="modal-section">
                        <span className="modal-details-label">Kod</span>
                        <span className="modal-details-text">
                            <DisplayOrInput isEdit={isAdd || isEdit}>
                                {codebook?.code}
                                <Input
                                    id="code"
                                    type="text"
                                    size="small"
                                    name="code"
                                    isValid={isValid("code")}
                                    value={codebook?.code}
                                    errorMessage={getErrorMsg("code")}
                                    onChange={changeCodebookProperty.bind(null, "code")}
                                />
                            </DisplayOrInput>
                        </span>
                    </div>
                )}
                {codebookType === CodebookType.Intervention && (
                    <div className="modal-section">
                        <span className="modal-details-label">Ciljna grupa</span>
                        <span className="modal-details-text">
                            <DisplayOrInput isEdit={isAdd || isEdit}>
                                {codebook?.targetGroup}
                                <Input
                                    id="targetGroup"
                                    type="text"
                                    size="small"
                                    name="targetGroup"
                                    isValid={isValid("targetGroup")}
                                    value={codebook?.targetGroup}
                                    errorMessage={getErrorMsg("targetGroup")}
                                    onChange={changeCodebookProperty.bind(null, "targetGroup")}
                                />
                            </DisplayOrInput>
                        </span>
                    </div>
                )}
                {codebookType === CodebookType.ProductionType && (
                    <div className="modal-section">
                        <span className="modal-details-label">Stručno područje</span>
                        <span>
                            <DisplayOrInput isEdit={isAdd || isEdit}>
                                {codebook?.professionalAreaName}
                                <SearchableDropdown
                                    items={professionalAreasAsItems}
                                    noOptionsMessage={() => "Nema pronađenih stručnih područja"}
                                    labelKey={"label"}
                                    onSelect={item =>
                                        setCodebook({
                                            ...codebook,
                                            professionalAreaGuid: item?.id ?? "",
                                        })
                                    }
                                    placeholder="Pretraživanje"
                                    customIcons={{
                                        searchIcon: <SearchIcon width={14} height={14} />,
                                    }}
                                    errorMessage={getErrorMsg("professionalAreaGuid")}
                                    isValid={isValid("professionalAreaGuid")}
                                />
                            </DisplayOrInput>
                        </span>
                    </div>
                )}
            </div>
        </Modal>
    );
};
