import React from "react";
import { ReactComponent as SortIcon } from "assets/icons/sortIcon.svg";
import { HeaderGroup, SortingRule } from "react-table";
import { TableType } from "./Table";

interface Props {
    headerGroups: HeaderGroup<object>[];
    sortBy?: SortingRule<object>;
    tableType: TableType;
}

function getSortIconClass(column: HeaderGroup<object>, sortBy: SortingRule<object> | undefined) {
    if (sortBy && sortBy.id === column.id) {
        return sortBy.desc ? "bottom-active" : "top-active";
    }
    return "";
}

export const TableHeader: React.FC<Props> = ({ headerGroups, sortBy, tableType }) => {
    return (
        <thead className="table-head">
            {headerGroups.map(headerGroup => (
                <tr className="table-srow" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => {
                        // Determine base classes based on tableType
                        const baseHeaderClass =
                            tableType === TableType.AdministrationTable
                                ? "administration-table-header"
                                : "education-table-header";

                        const baseInfoClass =
                            tableType === TableType.AdministrationTable
                                ? "administration-table__info"
                                : "education-table__info";

                        const centeredClass =
                            column.id === "centered" ? "table-centered-column" : "";

                        return column.id !== "info" ? (
                            <th
                                className={`${baseHeaderClass} ${centeredClass} ${
                                    column.className ?? ""
                                }`}
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                                <span>{column.render("Header")}</span>
                                {column.disableSortBy ? null : (
                                    <span className="table-header-icon">
                                        <SortIcon className={getSortIconClass(column, sortBy)} />
                                    </span>
                                )}
                            </th>
                        ) : (
                            <th
                                className={`${baseInfoClass} ${column.className ?? ""}`}
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                                {column.render("Header")}
                            </th>
                        );
                    })}
                </tr>
            ))}
        </thead>
    );
};
