import React, { useState } from "react";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down-dark-gray.svg";
interface AccordionProps {
    title: string;
    children: React.ReactNode;
    isOpenByDefault?: boolean;
    className?: string;
    buttonWithBorder?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
    title,
    children,
    isOpenByDefault = false,
    className = "",
    buttonWithBorder = false,
}) => {
    const [isOpen, setIsOpen] = useState(isOpenByDefault);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion ${className}`}>
            <button
                className={`accordion-header ${isOpen ? "accordion-header--active" : ""}`}
                onClick={toggleAccordion}
                aria-expanded={isOpen}
            >
                <span className="accordion-title">{title}</span>
                {buttonWithBorder ? (
                    <div className="accordion-icon-wrapper">
                        <ChevronDownIcon
                            className={
                                isOpen ? "accordion-icon accordion-icon--open" : "accordion-icon"
                            }
                        />
                    </div>
                ) : (
                    <ChevronDownIcon
                        className={
                            isOpen ? "accordion-icon accordion-icon--open" : "accordion-icon"
                        }
                    />
                )}
            </button>
            <div
                className={`accordion-content ${isOpen ? "accordion-content--open" : ""}`}
                aria-hidden={!isOpen}
            >
                {children}
            </div>
        </div>
    );
};
