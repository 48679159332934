import {
    Button,
    DatePicker,
    DisplayOrInput,
    FingerprintDisplay,
    Input,
    Modal,
    ModalFooter,
    ModalHeader,
} from "modules/common";
import { ConfirmationModal } from "modules/common/components/ConfirmationModal";
import { useEffect, useMemo, useState } from "react";
import {
    useBulkEditRecord,
    useCreateRecord,
    useDeleteRecord,
    useEditRecord,
    useGetRecordCategoriesAsItems,
} from "..";
import { Record as RecordEntry } from "services/records";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import SearchableDropdown from "modules/common/components/SearchableDropdown/SearchableDropdown";
import { Dropzone } from "modules/common/components/Dropzone";
import { useGetProductionTypesAsItems, useGetProfessionalAreasAsItems } from "modules/codebooks";
import { getDisplayDateFormat } from "helpers/DatesHelper";

export interface IRecordDetailsModal {
    records?: RecordEntry[];
    isOpen: boolean;
    handleClose: () => void;
}

const emptyRecord: RecordEntry = {
    guid: "",
    name: "",
    recordCategoryGuid: "",
    createdBy: "",
    created: "",
    lastUpdatedBy: "",
    lastUpdated: "",
    professionalAreaGuid: "",
    productionTypeGuid: "",
    deadline: "",
    fileName: "",
    uploadedFile: null,
};

type RecordProperty = keyof RecordEntry;

type RecordErrors = Partial<Record<RecordProperty, string>>;

export const RecordDetailsModal = ({
    records: selectedRecords,
    isOpen,
    handleClose,
}: IRecordDetailsModal) => {
    const [record, setRecord] = useState<RecordEntry>(emptyRecord);
    const [errors, setErrors] = useState<RecordErrors>({});
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState(false);
    const create = useCreateRecord(record.uploadedFile);
    const edit = useEditRecord(record.uploadedFile);
    const bulkEdit = useBulkEditRecord(selectedRecords?.map(record => record.guid) ?? []);
    const remove = useDeleteRecord();
    const [selectedProfessionalAreaGuid, setSelectedProfessionalAreaGuid] = useState<string>("");

    const professionalAreasAsItems = useGetProfessionalAreasAsItems();
    const recordCategoriesAsItems = useGetRecordCategoriesAsItems();
    const productionTypesAsItemsFullList = useGetProductionTypesAsItems();

    const productionTypesAsItems = useMemo(() => {
        if (!productionTypesAsItemsFullList) {
            return [];
        }
        if (record?.professionalAreaGuid) {
            const productionTypes = productionTypesAsItemsFullList?.filter(
                item => item.professionalAreaGuid === record.professionalAreaGuid
            );
            return productionTypes?.map(item => ({ label: item.label, id: item.id })) ?? [];
        } else if (selectedProfessionalAreaGuid) {
            const productionTypes = productionTypesAsItemsFullList?.filter(
                item => item.professionalAreaGuid === selectedProfessionalAreaGuid
            );
            return productionTypes?.map(item => ({ label: item.label, id: item.id })) ?? [];
        }
    }, [
        selectedProfessionalAreaGuid,
        record?.professionalAreaGuid,
        productionTypesAsItemsFullList,
    ]);

    const professionalAreasAsItemsLabel = useMemo(() => {
        return professionalAreasAsItems?.find(item => item.id === record?.professionalAreaGuid);
    }, [productionTypesAsItemsFullList, record.professionalAreaGuid]);

    const productionTypesAsItemsLabel = useMemo(() => {
        return productionTypesAsItems?.find(item => item.id === record?.productionTypeGuid);
    }, [productionTypesAsItemsFullList, record.productionTypeGuid]);

    const recordCategoriesAsItemsLabel = useMemo(() => {
        return recordCategoriesAsItems?.find(item => item.id === record?.recordCategoryGuid);
    }, [recordCategoriesAsItems, record.recordCategoryGuid]);

    const validateRecord = (record: RecordEntry): RecordErrors => {
        const errors: RecordErrors = {};
        if (!record.name) {
            errors.name = "Naziv kategorije je obavezan";
        }
        if (!record.professionalAreaGuid) {
            errors.professionalAreaGuid = "Područje je obavezno";
        }
        if (!record.productionTypeGuid) {
            errors.productionTypeGuid = "Vrsta proizvodnje je obavezna";
        }
        if (!record.deadline && !isBulkEdit) {
            errors.deadline = "Rok ispunjenja je obavezan";
        }
        if (!record.recordCategoryGuid) {
            errors.recordCategoryGuid = "Kategorija je obavezna";
        }
        if (record.uploadedFile === null && !isBulkEdit) {
            errors.uploadedFile = "Datoteka je obavezna";
        }
        return errors;
    };

    useEffect(() => {
        if (!selectedRecords) {
            return;
        }
        if (selectedRecords.length === 1) {
            setRecord(selectedRecords[0]);
        }
    }, [selectedRecords, setRecord]);

    useEffect(() => {
        setErrors({});
        setIsEdit(selectedRecords?.length ? selectedRecords.length > 1 : false);
        if (!isOpen) {
            setRecord(emptyRecord);
        }
    }, [isOpen, setErrors, setIsEdit]);

    const isAdd = useMemo(() => selectedRecords?.length == 0, [selectedRecords]);
    const isBulkEdit = useMemo(
        () => (selectedRecords ? selectedRecords?.length > 1 : false),
        [selectedRecords]
    );

    const title = useMemo(() => {
        if (isAdd) {
            return "Nova evidencija";
        }
        if (isEdit) {
            return "Uredi evidenciju";
        }
        return record?.name || "Detalji evidencije";
    }, [isAdd, isEdit, record?.name]);

    const changeRecordProperty = (
        property: RecordProperty,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setErrors(prevState => ({ ...prevState, [property]: undefined }));
        setRecord(prevState => ({ ...(prevState ?? emptyRecord), [property]: event.target.value }));
    };

    const isValid = (property: RecordProperty) => {
        return !errors[property];
    };

    const getErrorMsg = (property: RecordProperty) => {
        return errors[property] ?? "";
    };

    const onCancel = () => {
        setIsEdit(false);
        setErrors({});
        setRecord(record);
    };

    const onSave = async () => {
        const newErrors = validateRecord(record);
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }

        if (isBulkEdit) {
            await bulkEdit.mutateAsync(record);
        } else if (isAdd) {
            await create.mutateAsync(record);
        } else {
            await edit.mutateAsync(record);
        }

        setErrors({});
        handleClose();
    };

    const onDelete = async () => {
        if (!record) {
            return;
        }
        await remove.mutateAsync(record.guid);
        setShowDelete(false);
        handleClose();
    };

    return (
        <Modal
            isActive={isOpen}
            className="record-details-modal"
            onClose={handleClose}
            modalHeader={<ModalHeader onClose={handleClose} title={title} />}
            modalFooter={
                !record ? null : (
                    <ModalFooter>
                        {isAdd || isEdit ? (
                            <>
                                {isEdit && (
                                    <Button
                                        type="button"
                                        size="small"
                                        variant="primary-ghost"
                                        isDisabled={false}
                                        onClick={onCancel}
                                    >
                                        <span>ODUSTANI</span>
                                    </Button>
                                )}
                                <Button
                                    type="button"
                                    size="small"
                                    variant="primary"
                                    isDisabled={false}
                                    onClick={onSave}
                                >
                                    <span>SPREMI</span>
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    type="button"
                                    size="small"
                                    variant="warning-ghost"
                                    isDisabled={false}
                                    onClick={() => setShowDelete(true)}
                                >
                                    <span>OBRIŠI</span>
                                </Button>
                                <Button
                                    type="button"
                                    size="small"
                                    variant="primary-ghost"
                                    isDisabled={false}
                                    onClick={() => setIsEdit(true)}
                                >
                                    <span>UREDI</span>
                                </Button>
                            </>
                        )}
                    </ModalFooter>
                )
            }
        >
            <div className="record-details">
                {!isBulkEdit && (
                    <div className="modal-section">
                        <span className="modal-details-label">
                            {isAdd ? "DODAJ DATOTEKU" : isEdit ? "ZAMIJENI DATOTEKU" : "DATOTEKA"}
                        </span>

                        {isAdd || isEdit ? (
                            <Dropzone
                                handleDrop={(file: File) =>
                                    setRecord(prevState => ({
                                        ...prevState,
                                        uploadedFile: file,
                                    }))
                                }
                                isValid={isValid("uploadedFile")}
                                errorMessage={getErrorMsg("uploadedFile")}
                            />
                        ) : (
                            <span className="dropzone-file-name">{record?.fileName}</span>
                        )}
                    </div>
                )}
                <div className="modal-section">
                    <span className="modal-details-label">PODRUČJE</span>
                    <span>
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {professionalAreasAsItemsLabel?.label}
                            <SearchableDropdown
                                items={professionalAreasAsItems}
                                value={professionalAreasAsItemsLabel}
                                noOptionsMessage={() => "Nema pronađenih područja"}
                                labelKey="label"
                                onSelect={item => {
                                    setRecord(prevState => ({
                                        ...prevState,
                                        professionalAreaGuid: item?.id ?? "",
                                        productionTypeGuid: "",
                                    }));
                                    setSelectedProfessionalAreaGuid(item?.id ?? "");
                                }}
                                placeholder="Pretraživanje"
                                customIcons={{
                                    searchIcon: <SearchIcon width={14} height={14} />,
                                }}
                                isValid={isValid("professionalAreaGuid")}
                                errorMessage={getErrorMsg("professionalAreaGuid")}
                            />
                        </DisplayOrInput>
                    </span>
                    <span className="modal-details-label">VRSTA PROIZVODNJE</span>
                    <span>
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {productionTypesAsItemsLabel?.label}
                            <SearchableDropdown
                                items={productionTypesAsItems}
                                value={productionTypesAsItemsLabel}
                                noOptionsMessage={() => "Nema pronađenih vrsti proizvodnje"}
                                labelKey="label"
                                onSelect={item =>
                                    setRecord(prevState => ({
                                        ...prevState,
                                        productionTypeGuid: item?.id ?? "",
                                    }))
                                }
                                placeholder="Pretraživanje"
                                customIcons={{
                                    searchIcon: <SearchIcon width={14} height={14} />,
                                }}
                                isValid={isValid("productionTypeGuid")}
                                errorMessage={getErrorMsg("productionTypeGuid")}
                            />
                        </DisplayOrInput>
                    </span>
                    {!isBulkEdit && (
                        <>
                            <span className="modal-details-label">ROK ISPUNJENJA</span>
                            <span>
                                <DisplayOrInput isEdit={isAdd || isEdit}>
                                    {getDisplayDateFormat(record?.deadline)}
                                    <DatePicker
                                        value={
                                            record?.deadline
                                                ? new Date(record?.deadline)
                                                : undefined
                                        }
                                        selectedDay={new Date()}
                                        onClick={date =>
                                            setRecord(prevState => ({
                                                ...prevState,
                                                deadline: date.toISOString(),
                                            }))
                                        }
                                        isValid={isValid("deadline")}
                                        errorMessage={getErrorMsg("deadline")}
                                    />
                                </DisplayOrInput>
                            </span>
                        </>
                    )}
                </div>
                <div className="modal-section">
                    <span className="modal-details-label">KATEGORIJA</span>
                    <span>
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {recordCategoriesAsItemsLabel?.label}
                            <SearchableDropdown
                                items={recordCategoriesAsItems}
                                value={recordCategoriesAsItemsLabel}
                                noOptionsMessage={() => "Nema pronađenih kategorija"}
                                labelKey="label"
                                onSelect={item =>
                                    setRecord(prevState => ({
                                        ...prevState,
                                        recordCategoryGuid: item?.id ?? "",
                                    }))
                                }
                                placeholder="Pretraživanje"
                                customIcons={{
                                    searchIcon: <SearchIcon width={14} height={14} />,
                                }}
                                isValid={isValid("recordCategoryGuid")}
                                errorMessage={getErrorMsg("recordCategoryGuid")}
                            />
                        </DisplayOrInput>
                    </span>

                    <span className="modal-details-label">NAZIV KATEGORIJE</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {record?.name}
                            <Input
                                id="name"
                                type="text"
                                size="small"
                                name="name"
                                isValid={isValid("name")}
                                value={record?.name}
                                errorMessage={getErrorMsg("name")}
                                onChange={changeRecordProperty.bind(null, "name")}
                            />
                        </DisplayOrInput>
                    </span>
                </div>

                {(record.created !== "" || record.lastUpdated !== "") && (
                    <>
                        <div className="modal-section narrow">
                            <FingerprintDisplay
                                item={record}
                                value="created"
                                containerClassName="modal-section no-border"
                                labelClassName="modal-details-label"
                                textClassName="modal-details-text"
                            />
                        </div>
                        <div className="modal-section narrow">
                            <FingerprintDisplay
                                item={record}
                                value="lastUpdated"
                                containerClassName="modal-section no-border"
                                labelClassName="modal-details-label"
                                textClassName="modal-details-text"
                            />
                        </div>
                    </>
                )}
            </div>
            <ConfirmationModal
                isActive={showDelete}
                onConfirm={onDelete}
                onCancel={() => setShowDelete(false)}
                content={`Želite li izbrisati evidenciju ${record?.name}?`}
            />
        </Modal>
    );
};
