import { Button, WEEKDAYS_LONG } from "modules/common";
import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { EducationItem } from "services";
import { EducationReminders } from "./EducationReminders";
import { ReactComponent as Redirect } from "assets/icons/redirect.svg";
import { useReminderHandler } from "../hooks";

interface Props {
    selectedEducation: EducationItem | null;
    filter: number;
    setSelectedEducation: (value?: EducationItem | null) => void;
    onClick: (value: number) => void;
}

const FILTER_TITLES = ["Dostupni", "Prijavljeni", "Odslušali", "Podsjetnici"];

export const SpecificEducation: React.FC<Props> = ({
    selectedEducation,
    filter,
    setSelectedEducation,
    onClick,
}) => {
    const { handleAddReminder, pickedReminders } = useReminderHandler(selectedEducation);
    const [isReminderOpen, setIsReminderOpen] = useState(false);
    const reminderRef = useRef<HTMLDivElement[]>([]); // TODO: These refs are suspicious, change it when updating reminder logic
    reminderRef.current = [];
    const filterTitle = FILTER_TITLES[filter];

    function formatTime(data: string) {
        const date = new Date(data);

        return format(date, "HH:mm");
    }

    function handleReminderDropdownClick(educationItem: EducationItem) {
        setIsReminderOpen(prev => !prev);
        setSelectedEducation(educationItem);
    }

    function handleAddToRef(element: HTMLDivElement) {
        if (!element || reminderRef.current.includes(element)) return;
        reminderRef.current.push(element);
    }

    // Handle reminder modal closing
    useEffect(() => {
        if (!selectedEducation) return;
        if (!reminderRef || !reminderRef.current) return;
        let checks: boolean[] = [];
        document.addEventListener("click", event => {
            checks = [];
            for (const reminder of reminderRef.current) {
                checks.push(reminder.contains(event.target as Node));
            }

            if (checks.includes(true)) return;
            setIsReminderOpen(false);
        });
    }, [reminderRef]);

    return (
        selectedEducation && (
            <>
                {/* Header Section */}
                <div className="education__item-header">
                    <h2 className="education__item-title">{selectedEducation.title}</h2>
                    <div className="education-date-row">
                        <h3 className="education__item-date">
                            {WEEKDAYS_LONG[new Date(selectedEducation.startDate).getDay()]},{" "}
                            {format(new Date(selectedEducation.startDate), "dd.MM.yyyy.")}
                        </h3>
                        <h3
                            className={`education__item-time ${
                                filter !== 2 && "education__item-time--active"
                            }`}
                        >
                            {`${formatTime(selectedEducation.startDate)} - ${formatTime(
                                selectedEducation.endDate
                            )}`}
                        </h3>
                    </div>
                    <p className="education__item-location">{selectedEducation.address}</p>
                    <div className="education__item-badges">
                        {selectedEducation.county && (
                            <p className="educations__item-subheading educations__item-subheading--first">
                                {selectedEducation.county}
                            </p>
                        )}
                        {selectedEducation.expertArea && (
                            <p className="educations__item-subheading educations__item-subheading--second">
                                {selectedEducation.expertArea}
                            </p>
                        )}
                        {selectedEducation.measure && (
                            <p className="educations__item-subheading educations__item-subheading--third">
                                {selectedEducation.measure}
                            </p>
                        )}
                    </div>
                </div>

                <div className="separator" />

                {/* Reminder Section */}
                {filter !== 2 ? (
                    new Date(selectedEducation.registrationDeadline) > new Date() && (
                        <div className="education__item-header">
                            <div className="education__list__reminder-wrapper" ref={handleAddToRef}>
                                <Button
                                    size="xxsmall"
                                    variant="primary-ghost"
                                    className={`btn--nowrap mr-8 ${
                                        pickedReminders.find(
                                            reminder =>
                                                reminder.education.uid === selectedEducation.uid
                                        ) && "btn--reminder-active"
                                    }`}
                                    isDisabled={false}
                                    onClick={() => handleReminderDropdownClick(selectedEducation)}
                                >
                                    Podsjeti me
                                </Button>
                                <EducationReminders
                                    isReminderOpen={isReminderOpen}
                                    mappedEducation={selectedEducation}
                                    pickedEducation={selectedEducation}
                                    handleAddReminder={handleAddReminder}
                                />
                            </div>
                            <Button
                                size="xxsmall"
                                variant="primary-ghost"
                                className="btn--nowrap"
                                isDisabled={
                                    new Date(selectedEducation.registrationDeadline) <= new Date()
                                }
                                onClick={() => onClick(selectedEducation.semisId)}
                            >
                                Prijavi se
                            </Button>
                        </div>
                    )
                ) : (
                    <div>
                        <Button
                            size="xxsmall"
                            variant="primary-ghost"
                            className="btn--nowrap"
                            isDisabled={false}
                            to="https://www.savjetodavna.hr/potvrde/" // TODO: Add correct link
                        >
                            Preuzmite potvrdu <Redirect className="ml-8" />
                        </Button>
                    </div>
                )}

                {/* Details Section */}
                <div className="education__item-details">
                    <div>
                        <h5 className="education__item-label">Kapacitet</h5>
                        <p className="mt-8 mb-24">{`${selectedEducation.spotsAvailable}/${selectedEducation.maxAttendees}`}</p>

                        <h5 className="education__item-label">Obaveza</h5>
                        <p
                            className={`mt-8 education__item-obligation ${
                                selectedEducation.isEducationMandatoryForFarmer &&
                                "education__item-obligation--active"
                            }`}
                        >
                            {selectedEducation.isEducationMandatoryForFarmer ? "Da" : "Ne"}
                        </p>
                    </div>

                    <div>
                        <h5 className="education__item-label">Predavači</h5>
                        {selectedEducation.instructors.map((value, index) => (
                            <p className="mt-8" key={index}>
                                {value.name}
                            </p>
                        ))}
                    </div>

                    <div>
                        <h5 className="education__item-label">Napomena</h5>
                        <p className="mt-8">{selectedEducation.remark}</p>
                    </div>
                </div>

                {/* Status Section */}
                {selectedEducation.educationStatuses?.length > 0 && (
                    <div>
                        <h5 className="education__item-label">{filterTitle}</h5>
                        <p className="f mt-8">
                            {selectedEducation.educationStatuses.map((value, index) => {
                                const showComma =
                                    index + 1 < selectedEducation.educationStatuses.length;

                                return (
                                    <React.Fragment key={index}>
                                        {value.farmerName} {value.farmerLastName}
                                        {showComma && ", "}
                                    </React.Fragment>
                                );
                            })}
                        </p>
                    </div>
                )}
            </>
        )
    );
};
