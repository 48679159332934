import { Button, Modal, ModalFooter, ModalHeader } from "modules/common";
import React from "react";
import { useDispatch } from "react-redux";
import { deactivateAccount, deactivateUserByUid } from "modules/dashboard";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "routing";

interface Props {
    isVisible: boolean;
    toggleModal: () => void;
    userUuId: string;
    userRole: number;
}

export const DeactivateAccountModal: React.FC<Props> = ({
    isVisible,
    toggleModal,
    userUuId,
    userRole,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    function submit() {
        dispatch(deactivateUserByUid(userUuId, userRole, true));
        history.push(AppRoutes.Home);
        toggleModal();
    }

    return (
        <Modal
            isActive={isVisible}
            onClose={toggleModal}
            modalHeader={
                <ModalHeader onClose={toggleModal} title="Deaktiviraj račun" isLoading={false} />
            }
            modalFooter={
                <ModalFooter>
                    <Button
                        type="button"
                        size="small"
                        variant="primary-ghost"
                        isDisabled={false}
                        onClick={toggleModal}
                    >
                        <span>Odustani</span>
                    </Button>
                    <Button
                        type="button"
                        size="small"
                        variant="warning-ghost"
                        isDisabled={false}
                        onClick={submit}
                    >
                        <span>Deaktiviraj račun</span>
                    </Button>
                </ModalFooter>
            }
        >
            <p className="mt-40 mr-40 mb-40 ml-40">
                Jeste li sigurni da želite deaktivirati korisnički račun? Deaktivacijom računa
                gubite prava pristupa sustavu e-Savjetnik. Ukoliko biste htjeli ponovo pristupiti
                sustavu, potrebno je ponovo se registrirati.
            </p>
        </Modal>
    );
};
