import { getAuthHeaders, getFormData } from "modules/common";
import { createService } from "services/serviceFactory";
import {
    CreateCodebookEntryRequest,
    GetCodebookEntriesRequest,
    EditCodebookEntryRequest,
    DeactivateCodebookEntryRequest,
} from "./request";
import { CodebookEntry, CodebookEntryResponse } from "./response";

const codebooksService = createService("codebooks");

async function getCodebookEntries(data: GetCodebookEntriesRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await codebooksService.get<CodebookEntryResponse>("/codebooks/v1/codebooks", {
        params: data,
        ...authHeaders,
    });
    return response.data;
}

async function createCodebookEntry(data: CreateCodebookEntryRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = getFormData(data);
    const response = await codebooksService.post<CodebookEntry>(
        "/codebooks/v1/codebooks",
        formData,
        authHeaders
    );
    return response.data;
}

async function editCodebookEntry(data: EditCodebookEntryRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = getFormData(data);
    const response = await codebooksService.patch<CodebookEntry>(
        `/codebooks/v1/codebooks`,
        formData,
        authHeaders
    );
    return response.data;
}

async function deactivateCodebookEntry(data: DeactivateCodebookEntryRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = getFormData(data);
    const response = await codebooksService.post<CodebookEntry>(
        `/codebooks/v1/codebooks/deactivate`,
        formData,
        authHeaders
    );
    return response.data;
}

export { getCodebookEntries, createCodebookEntry, editCodebookEntry, deactivateCodebookEntry };
