import { Filter, ToggleSwitch } from "modules/common";
import React from "react";

interface Props {
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    isActive: boolean;
    showDropdown: boolean;
    values: string[];
    list: {
        key: string;
        value: string;
    }[];
}

export const GenericSwitchFilter: React.FC<Props> = ({
    name,
    onChange,
    onClick,
    onClear,
    isActive,
    showDropdown,
    values,
    list,
}) => {
    return (
        <div className="generic-switch-filter">
            <Filter
                isActive={isActive}
                showDropdown={showDropdown}
                onClear={onClear}
                onClick={onClick}
                text={name}
            >
                {list.map(item => (
                    <div key={item.key}>
                        <hr />
                        <div className="filter-switch">
                            <span className="filter__item__label">
                                {item.value.toLocaleUpperCase()}
                            </span>
                            <ToggleSwitch
                                htmlFor={item.key}
                                value={item.key}
                                onChange={onChange}
                                checked={values.includes(item.key)}
                            >
                                {item.value}
                            </ToggleSwitch>
                        </div>
                    </div>
                ))}
            </Filter>
        </div>
    );
};
