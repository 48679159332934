import { Button, Input, useTitle } from "modules/common";
import React, { useEffect, useMemo, useState } from "react";
import { PanelMenu, PanelMenuItem } from "modules/common/components/PanelMenu";
import {
    RecordCategoryModal,
    RecordDetailsModal,
    RecordsTable,
    useGetRecordCategories,
    useGetRecords,
} from "modules/records";
import { GetRecordRequest, RecordCategory } from "services/records";
import { Record } from "services/records";
import { RecordsFilters } from "modules/records/components/RecordsFilters";
import useDebounced from "modules/common/hooks/useDebounced";

const INPUT_DEBOUNCE_TIME = 500;

const initialRequest: GetRecordRequest = {
    page: 1,
    pageSize: 1000,
    search: null,
    professionalAreaGuids: [],
    productionTypeGuids: [],
    deadlineFilters: [],
};

export const Records: React.FC = () => {
    useTitle("Evidencije - eSavjetnik");

    const [menuItem, setMenuItem] = useState<PanelMenuItem>();
    const [isRecordModalOpen, setIsRecordModalOpen] = useState(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [request, setRequest] = useState<GetRecordRequest>(initialRequest);
    const [professionalAreaFilter, setProfessionalAreaFilter] = useState<string[]>([]);
    const [productionTypeFilter, setProductionTypeFilter] = useState<string[]>([]);
    const [deadlineFilter, setDeadlineFilter] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<Record[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<RecordCategory>();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const search = useDebounced(searchTerm, INPUT_DEBOUNCE_TIME);

    const { data: recordCategoriesData, isLoading: isRecordCategoriesLoading } =
        useGetRecordCategories(search ?? "");
    const { data: recordsData, isLoading: isRecordsLoading } = useGetRecords(request);

    const menuItems = useMemo(
        () =>
            recordCategoriesData?.map(category => ({
                link: category.guid,
                label: category.name,
            })),
        [recordCategoriesData]
    );

    useEffect(() => {
        setMenuItem(menuItems?.[0]);
        setSelectedCategory(recordCategoriesData?.[0]);
        setRequest({ ...request, recordCategoryGuid: menuItems?.[0]?.link });
    }, [menuItems]);

    // update request on filters change
    useEffect(() => {
        setRequest({
            ...request,
            professionalAreaGuids: professionalAreaFilter,
            productionTypeGuids: productionTypeFilter,
            deadlineFilters: deadlineFilter,
        });
    }, [professionalAreaFilter, productionTypeFilter, deadlineFilter]);

    const handleSearchCategory = (search: string) => {
        setSearchTerm(search);
    };

    return (
        <div className="records-container">
            <div className="records-panel">
                <div className="records-search">
                    <Input
                        id="search"
                        type="text"
                        size="large"
                        isValid={true}
                        name="search"
                        errorMessage=""
                        placeholder="Pretraži ..."
                        onChange={e => handleSearchCategory(e.target.value)}
                    />
                </div>
                <div className="record-categories-menu">
                    <PanelMenu
                        items={menuItems}
                        selectedItem={menuItem}
                        onItemClicked={item => {
                            setMenuItem(item);
                            setRequest({ ...request, recordCategoryGuid: item.link });
                            setSelectedCategory(
                                recordCategoriesData?.find(category => category.guid === item.link)
                            );
                        }}
                    />
                    <div className="record-categories-menu-buttons">
                        <Button
                            size="small"
                            variant="primary-ghost"
                            isDisabled={isRecordCategoriesLoading || !selectedCategory}
                            onClick={() => setIsCategoryModalOpen(Boolean(selectedCategory))}
                        >
                            UREDI
                        </Button>
                        <Button
                            size="small"
                            variant="primary"
                            isDisabled={isRecordCategoriesLoading}
                            onClick={() => {
                                setSelectedCategory(undefined);
                                setIsCategoryModalOpen(true);
                            }}
                        >
                            NOVA KATEGORIJA
                        </Button>
                    </div>
                </div>
            </div>

            <div className="records-content">
                <div className="records-heading">
                    <div className="records-filters">
                        <span>EVIDENCIJE</span>
                        <RecordsFilters
                            professionalAreaFilter={professionalAreaFilter}
                            productionTypeFilter={productionTypeFilter}
                            deadlineFilter={deadlineFilter}
                            setProfessionalAreaFilter={setProfessionalAreaFilter}
                            setProductionTypeFilter={setProductionTypeFilter}
                            setDeadlineFilter={setDeadlineFilter}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                    <Button
                        size="small"
                        className="records-button"
                        variant="primary"
                        isDisabled={selectedRows.length < 2}
                        onClick={() => setIsRecordModalOpen(true)}
                    >
                        UREDI GRUPNO
                    </Button>
                    <Button
                        size="small"
                        className="records-button"
                        variant="primary"
                        isDisabled={
                            isRecordCategoriesLoading || isRecordsLoading || selectedRows.length > 0
                        }
                        onClick={() => setIsRecordModalOpen(true)}
                    >
                        DODAJ
                    </Button>
                </div>

                {menuItem && (
                    <RecordsTable
                        records={recordsData}
                        isLoading={isRecordsLoading}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        page={currentPage}
                    />
                )}
            </div>

            {isRecordModalOpen && (
                <RecordDetailsModal
                    isOpen={isRecordModalOpen}
                    handleClose={() => setIsRecordModalOpen(false)}
                    records={selectedRows}
                />
            )}

            <RecordCategoryModal
                isOpen={isCategoryModalOpen}
                handleClose={() => setIsCategoryModalOpen(false)}
                recordCategory={selectedCategory}
            />
        </div>
    );
};
