import { UserAccount } from "services";
import { mapCounty, mapUserRoles } from "./mappingUtils";

export const mapUserProfile = (userAccount: UserAccount) => {
    const role = mapUserRoles(userAccount.roleType);
    const county = mapCounty(userAccount.countyId);

    return {
        uid: userAccount.uid ?? "",
        mibpg: userAccount.mibpg ?? "",
        email: userAccount.email ?? "",
        username: userAccount.username ?? "",
        legalEntityName: userAccount.legalEntityName ?? "",
        firstName: userAccount.firstName ?? "",
        lastName: userAccount.lastName ?? "",
        street: userAccount.street ?? "",
        streetNumber: userAccount.streetNumber ?? "",
        postalNumber: userAccount.postalNumber ?? "",
        city: userAccount.city ?? "",
        settlement: userAccount.settlement ?? "",
        settlementId: userAccount.settlementId ?? "",
        country: userAccount.country ?? "",
        phoneNumber: userAccount.phoneNumber ?? "",
        mobileNumber: userAccount.mobileNumber ?? "",
        additionalMobileNumber: userAccount.additionalMobileNumber ?? "",
        county: county?.label ?? "",
        countyId: county?.value ?? "",
        roleType: role,
        notificationType: userAccount.notificationType,
        infoType: userAccount.infoType,
        plantProtectionCounties: userAccount.plantProtectionCounties,
        infoProfessionalField: userAccount.infoProfessionalField,
        manifestationsCounties: userAccount.manifestationsCounties,
        manifestationsProfessionalField: userAccount.manifestationsProfessionalField,
        members: userAccount.members,
        isActive: userAccount.isActive,
    };
};

export type MappedUserProfile = ReturnType<typeof mapUserProfile>;
