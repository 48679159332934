import {
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "@tanstack/react-query";
import { useAccessToken, useStandardErrorHandler } from "modules/common";
import { toast } from "react-toastify";
import {
    getRecords,
    createRecord,
    GetRecordRequest,
    RecordResponse,
    CreateRecordRequest,
    editRecord,
    EditRecordRequest,
    deleteRecord,
    RecordCategory,
    CreateRecordCategoryRequest,
    createRecordCategory,
    EditRecordCategoryRequest,
    editRecordCategory,
    getRecordCategories,
    downloadRecordFile,
    bulkEditRecords,
    Record as RecordEntry,
} from "services/records";
import mime from "mime";

export const useGetRecords = (
    data: GetRecordRequest,
    options?: UseQueryOptions<RecordResponse>
) => {
    const errorHandler = useStandardErrorHandler("Greška pri dohvaćanju vanjskih modula");
    const accessToken = useAccessToken();
    const { enabled, ...opts } = options ?? {};

    const queryFn = () => getRecords(data, accessToken!);

    return useQuery({
        queryKey: ["records", data],
        queryFn,
        onError: errorHandler,
        keepPreviousData: true,
        ...opts,
        enabled: Boolean(data.recordCategoryGuid),
    });
};

export const useGetRecordCategoriesAsItems = () => {
    const { data: recordCategoriesData } = useGetRecordCategories("");

    return recordCategoriesData?.map(category => ({
        label: category.name,
        id: category.guid,
    }));
};

export const useCreateRecord = (
    uploadedFile: File | null,
    options?: UseMutationOptions<RecordEntry, unknown, CreateRecordRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri stvaranju evidencije");

    return useMutation({
        mutationFn: (data: CreateRecordRequest) => createRecord(data, uploadedFile, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["records"]);
            toast.success("Evidencija je uspješno stvorena");
        },
        ...options,
    });
};

export const useEditRecord = (uploadedFile: File | null) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri uređivanju evidencije");

    return useMutation({
        mutationFn: (data: EditRecordRequest) => editRecord(data, uploadedFile, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["records"]);
            toast.success("Evidencija je uspješno uređena");
        },
    });
};

export const useBulkEditRecord = (recordGuids: string[]) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri grupnom uređivanju evidencije");

    return useMutation({
        mutationFn: (data: EditRecordRequest) => bulkEditRecords(data, recordGuids, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["records"]);
            toast.success("Evidencije su uspješno uređen");
        },
    });
};

export const useDeleteRecord = () => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri brisanju evidencije");

    return useMutation({
        mutationFn: (id: string) => deleteRecord(id, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["records"]);
            toast.success("Evidencija je uspješno obrisana");
        },
    });
};

export const useGetRecordCategories = (
    searchTerm: string,
    options?: UseQueryOptions<RecordCategory[]>
) => {
    const errorHandler = useStandardErrorHandler("Greška pri dohvaćanju kategorija evidencije");
    const accessToken = useAccessToken();
    const { enabled, ...opts } = options ?? {};

    const queryFn = () => getRecordCategories(searchTerm, accessToken!);

    return useQuery({
        queryKey: ["recordCategories", searchTerm],
        queryFn,
        onError: errorHandler,
        keepPreviousData: true,
        ...opts,
    });
};

export const useCreateRecordCategory = (
    options?: UseMutationOptions<RecordCategory, unknown, CreateRecordCategoryRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri stvaranju katogorije evidencije");

    return useMutation({
        mutationFn: (data: CreateRecordCategoryRequest) => createRecordCategory(data, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["recordCategories"]);
            toast.success("Kategorija evidencije je uspješno stvorena");
        },
        ...options,
    });
};

export const useEditRecordCategory = (
    options?: UseMutationOptions<RecordCategory, unknown, EditRecordCategoryRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri uređivanju kategorije evidencije");

    return useMutation({
        mutationFn: (data: EditRecordCategoryRequest) => editRecordCategory(data, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["recordCategories"]);
            toast.success("Kategorija evidencije je uspješno uređena");
        },
        ...options,
    });
};

export const downloadFile = async (recordGuid: string, accessToken: string) => {
    const response = await downloadRecordFile(recordGuid, accessToken);
    const url = window.URL.createObjectURL(new Blob([response], { type: response.type }));
    const link = document.createElement("a");
    link.href = url;
    const fileExtension = mime.getExtension(response.type);
    link.setAttribute("download", `${recordGuid}.${fileExtension}`);
    document.body.appendChild(link);
    link.click();
};
