import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DayModifiers } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import { ValueType } from "react-select";
import debounce from "lodash.debounce";
import {
    Input,
    DatePicker,
    Table,
    userActivityColumns,
    Pagination,
    OptionType,
    Loading,
    NoResults,
    useTitle,
} from "modules/common";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { RootState } from "store";
import { ActivityFilters, getUserActivityData, getUserActivityRequest } from "modules/dashboard";

const DefaultPageSize = {
    value: "10",
    label: "10",
};

const PAGE_NEIGHBOURS = 1;
const INPUT_DEBOUNCE_TIME = 500; //miliseconds

export const UserActivity: React.FC = () => {
    useTitle("Aktivnost korisnika - eSavjetnik");
    const dispatch = useDispatch();
    const userActivity = useSelector((state: RootState) => state.userActivity);
    const [actionFilter, setActionFilter] = useState<number[]>([]);
    const [roleFilter, setRoleFilter] = useState<number[]>([]);
    const [pageSize, setPageSize] = useState<OptionType | null>(DefaultPageSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const tableColumns = useMemo(() => userActivityColumns, []);
    const [date, setDate] = useState<Date | null>(null);

    const handleDayClick = (
        day: Date,
        modifiers: DayModifiers,
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        setDate(new Date(day.toISOString()));
        setCurrentPage(1);
    };

    const handlePageSizeChange = (value: ValueType<OptionType, false>) => {
        setPageSize(value);
        setCurrentPage(1);
    };

    const handlePageClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const page = Number(parseInt(event.currentTarget.id));
        setCurrentPage(page);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(
            (
                currentPage: number,
                searchTerm: string | null,
                size: number,
                actions: number[],
                roles: number[],
                date: Date | null
            ) =>
                dispatch(
                    getUserActivityData({
                        pageNumber: currentPage,
                        pageSize: size,
                        actionTypeFilter: [...actions],
                        applicationTypeFilter: [0],
                        userRoleFilter: [...roles],
                        search: searchTerm,
                        dateFrom: date ? new Date(date.setHours(0, 0, 0, 0)) : null,
                        dateTo: date ? new Date(date.setHours(23, 59, 59, 999)) : null,
                    })
                ),
            INPUT_DEBOUNCE_TIME
        ),
        []
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearchTerm(value);
        setCurrentPage(1);
    };

    const handleNexPageClick = () => {
        setCurrentPage(prev => prev + 1);
    };

    const handlePrevPageClick = () => {
        setCurrentPage(prev => prev - 1);
    };

    const handleFirstPageClick = () => {
        setCurrentPage(1);
    };

    const handleLastPageClick = () => {
        setCurrentPage(
            Math.ceil(
                userActivity.userActivityLogCount /
                    parseInt(pageSize?.value ?? DefaultPageSize.value)
            )
        );
    };

    const handlePageSelect = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const size = parseInt(pageSize?.value ?? DefaultPageSize.value);
        // api call is debounced, switch to loading earlier
        dispatch(getUserActivityRequest());
        debouncedSearch(currentPage, searchTerm, size, actionFilter, roleFilter, date);
    }, [
        actionFilter,
        currentPage,
        date,
        debouncedSearch,
        dispatch,
        pageSize?.value,
        roleFilter,
        searchTerm,
    ]);

    return (
        <div className="user-activity">
            <div className="user-activity-heading">
                <div className="user-activity-filters">
                    <span>AKTIVNOST KORISNIKA</span>
                    <ActivityFilters
                        actionFilter={actionFilter}
                        roleFilter={roleFilter}
                        setActionFilter={setActionFilter}
                        setRoleFilter={setRoleFilter}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
                <div className="user-activity-date-filter">
                    <DatePicker onClick={handleDayClick} selectedDay={date} />
                </div>
                <Input
                    name="search"
                    className="search"
                    id="search"
                    type="text"
                    size="small"
                    placeholder="Pretraži"
                    isValid={true}
                    onChange={handleSearchChange}
                    errorMessage=""
                    leftIcon={<SearchIcon className="search-icon" />}
                />
            </div>
            {userActivity.isLoading ? (
                <Loading />
            ) : userActivity.data && userActivity.data?.length ? (
                <>
                    <div className="table-container">
                        <Table data={userActivity.data} columns={tableColumns} />
                    </div>
                    {userActivity.data.length === 0 ? null : (
                        <Pagination
                            className="mt-24"
                            currentPage={currentPage}
                            numberOfPages={Math.ceil(
                                userActivity.userActivityLogCount /
                                    parseInt(pageSize?.value ?? DefaultPageSize.value)
                            )}
                            pageNeighbours={PAGE_NEIGHBOURS}
                            pageSize={pageSize ?? DefaultPageSize}
                            onPageSizeChange={handlePageSizeChange}
                            handlepageClick={handlePageClick}
                            next={handleNexPageClick}
                            prev={handlePrevPageClick}
                            first={handleFirstPageClick}
                            last={handleLastPageClick}
                            handlePageSelect={handlePageSelect}
                        />
                    )}
                </>
            ) : (
                <NoResults className="mt-24" />
            )}
        </div>
    );
};
