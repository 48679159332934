import { Filter, ToggleSwitch } from "modules/common";
import { deadlineTypes } from "modules/common/constants/deadlineTypes";
import React, { useMemo } from "react";

interface Props {
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    isActive: boolean;
    showDropdown: boolean;
    values: number[];
}

export const DeadlineFilter: React.FC<Props> = ({
    name,
    onChange,
    onClick,
    onClear,
    isActive,
    showDropdown,
    values,
}) => {
    const deadlineTypesLabels = useMemo(() => {
        return Object.keys(deadlineTypes).filter(key => deadlineTypes[key].value !== 0);
    }, []);

    return (
        <div className="generic-switch-filter">
            <Filter
                isActive={isActive}
                showDropdown={showDropdown}
                onClear={onClear}
                onClick={onClick}
                text={name}
            >
                {deadlineTypesLabels.map((key, index) => (
                    <div key={index}>
                        <hr />
                        <div className="filter-switch">
                            <span className="filter__item__label">
                                {deadlineTypes[key].label.toLocaleUpperCase()}
                            </span>
                            <ToggleSwitch
                                htmlFor={key}
                                value={deadlineTypes[key].value.toString()}
                                onChange={onChange}
                                checked={values.includes(deadlineTypes[key].value)}
                            >
                                {deadlineTypes[key].label.toLocaleUpperCase()}
                            </ToggleSwitch>
                        </div>
                    </div>
                ))}
            </Filter>
        </div>
    );
};
