import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "routing";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { RoleType, useOutsideClick } from "modules/common";
import { Can } from "modules/auth";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface Props {
    close: () => void;
    className?: string;
    isOpen: boolean;
}
export const HamburgerMenu: React.FC<Props> = ({ close, className, isOpen }) => {
    const hamburgerMenuRef = useRef<HTMLDivElement>(null);
    const { profile } = useSelector((state: RootState) => state.profile);

    const handleOutsideClick = () => close();
    useOutsideClick(hamburgerMenuRef, handleOutsideClick);

    return (
        <>
            {isOpen && <div className="modal-overlay" />}
            <nav
                ref={hamburgerMenuRef}
                role="navigation"
                className={`hamburger-menu ${className ?? ""}`}
            >
                <div className="hamburger-menu__close">
                    <CloseIcon onClick={close} className="hamburger-menu__close-icon" />
                </div>
                <Can I="view" a="SuperAdminFilter">
                    <Can I="view" a="UserActivityModule">
                        <NavLink
                            to={AppRoutes.UserActivity}
                            activeClassName="hamurger-menu__item--active"
                            className="hamburger-menu__item"
                        >
                            Aktivnost korisnika
                        </NavLink>
                    </Can>
                    <Can I="view" a="UserAdministrationModule">
                        <NavLink
                            to={AppRoutes.UserAdministration}
                            activeClassName="hamurger-menu__item--active"
                            className="hamburger-menu__item"
                        >
                            Administracija korisnika
                        </NavLink>
                    </Can>
                    <Can I="view" a="SystemAdministrationModule">
                        <NavLink
                            to={AppRoutes.SystemAdministration}
                            activeClassName="hamurger-menu__item--active"
                            className="hamburger-menu__item"
                        >
                            Administracija sustava
                        </NavLink>
                    </Can>
                </Can>

                <NavLink
                    activeClassName="hamurger-menu__item--active"
                    className="hamburger-menu__item"
                    to={AppRoutes.News}
                >
                    Vijesti i savjeti
                </NavLink>
                <NavLink
                    activeClassName="hamurger-menu__item--active"
                    className="hamburger-menu__item"
                    to={AppRoutes.Agrometeorology}
                >
                    Agrometeorologija
                </NavLink>
                <Can I="view" a="AgroclimateStations">
                    <NavLink
                        activeClassName="hamurger-menu__item--active"
                        className="hamburger-menu__item"
                        to={AppRoutes.AgroclimateStations}
                    >
                        Agroklimatske stanice
                    </NavLink>
                </Can>

                <NavLink
                    activeClassName="hamurger-menu__item--active"
                    className="hamburger-menu__item"
                    to={AppRoutes.PlantProtection}
                >
                    Zaštita bilja
                </NavLink>

                <Can I="view" a="CommunicationModule">
                    <NavLink
                        activeClassName="hamurger-menu__item--active"
                        className="hamburger-menu__item"
                        to={AppRoutes.Communication}
                    >
                        Komunikacija
                    </NavLink>
                </Can>
                <NavLink
                    activeClassName="hamurger-menu__item--active"
                    className="hamburger-menu__item"
                    to={AppRoutes.Records}
                >
                    Evidencije
                </NavLink>
                <Can I="view" a="EducationModule">
                    <NavLink
                        activeClassName="hamurger-menu__item--active"
                        className="hamburger-menu__item"
                        to={AppRoutes.Education}
                    >
                        Tečajevi
                    </NavLink>
                </Can>

                <Can I="view" a="InternalSystemsModule">
                    <Can I="view" a="SEMIS">
                        <a
                            href="https://semis.mps.hr"
                            rel="noreferrer"
                            target="_blank"
                            className="hamburger-menu__item"
                        >
                            SEMIS
                        </a>
                    </Can>
                    <Can I="view" a="InternalDocumentation">
                        <a
                            href="https://o365mps.sharepoint.com/_layouts/15/sharepoint.aspx"
                            rel="noreferrer"
                            target="_blank"
                            className="hamburger-menu__item"
                        >
                            Sharepoint
                        </a>
                    </Can>
                    <Can I="view" a="Webtop">
                        <a
                            href="https://webtop.mps.hr/"
                            rel="noreferrer"
                            target="_blank"
                            className="hamburger-menu__item"
                        >
                            Intranet
                        </a>
                    </Can>
                    <Can I="view" a="ReportProblem">
                        <a
                            href="https://podrska.mps.hr/"
                            rel="noreferrer"
                            target="_blank"
                            className="hamburger-menu__item"
                        >
                            IT podrška
                        </a>
                    </Can>
                    <a
                        href="https://savjetodavna.mps.hr/wp-login.php "
                        rel="noreferrer"
                        target="_blank"
                        className="hamburger-menu__item"
                    >
                        Web autori
                    </a>
                    <Can I="view" a="PIO">
                        <a
                            href="https://pitanja.mps.hr/login"
                            rel="noreferrer"
                            target="_blank"
                            className="hamburger-menu__item"
                        >
                            PIO za odgovarače
                        </a>
                    </Can>
                </Can>

                <a
                    href="https://www.savjetodavna.mps.hr/"
                    rel="noreferrer"
                    target="_blank"
                    className="hamburger-menu__item"
                >
                    Savjetodavni portal
                </a>

                <a
                    href="https://poljoprivreda.gov.hr"
                    rel="noreferrer"
                    target="_blank"
                    className="hamburger-menu__item"
                >
                    Ministarstvo poljoprivrede
                </a>

                <a
                    href="https://www.apprrr.hr/"
                    rel="noreferrer"
                    target="_blank"
                    className="hamburger-menu__item"
                >
                    APPRRR
                </a>

                <a
                    href="https://stoka.hpa.hr/vetis"
                    rel="noreferrer"
                    target="_blank"
                    className="hamburger-menu__item"
                >
                    VETIS
                </a>

                <a
                    href="https://agronet.apprrr.hr/"
                    rel="noreferrer"
                    target="_blank"
                    className="hamburger-menu__item"
                >
                    Agronet
                </a>

                <a
                    href="https://pitanja.mps.hr"
                    rel="noreferrer"
                    target="_blank"
                    className="hamburger-menu__item"
                >
                    Postavi upit
                </a>
            </nav>
        </>
    );
};
