import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ValueType } from "react-select";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import {
    Input,
    CustomTable,
    Pagination,
    OptionType,
    Modal,
    ModalHeader,
    UserInfo,
    Loading,
    findUserRoleByLabel,
    UserInfoLoading,
    useTitle,
    TableType,
} from "modules/common";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as DetailsIcon } from "assets/icons/details.svg";
import {
    AdministrationFilters,
    deactivateUserByUid,
    getUserAccountData,
    getAccountList,
    getAccountListRequest,
} from "modules/dashboard";
import { RootState } from "store";
import { SortingRule } from "react-table";

const DefaultPageSize = {
    value: "10",
    label: "10",
};

const PAGE_NEIGHBOURS = 1;
const INPUT_DEBOUNCE_TIME = 500; //miliseconds

export const UserAdministration: React.FC = () => {
    useTitle("Administracija korisnika - eSavjetnik");
    const dispatch = useDispatch();
    const accountList = useSelector((state: RootState) => state.accountList);
    const userAccount = useSelector((state: RootState) => state.userAccount);
    const { userData } = userAccount;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageSize, setPageSize] = useState<OptionType | null>(DefaultPageSize);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [countyFilter, setCountyFilter] = useState<number[]>([]);
    const [roleFilter, setRoleFilter] = useState<number[]>([]);
    const [statusFilter, setStatusFilter] = useState<number>(-1);
    const [sortBy, setSortBy] = useState<SortingRule<object>>();

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearchTerm(value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (value: ValueType<OptionType, false>) => {
        setPageSize(value);
        setCurrentPage(1);
    };

    const handlePageClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        setCurrentPage(Number(parseInt(event.currentTarget.id)));
    };

    const handleNexPageClick = () => {
        setCurrentPage(prev => prev + 1);
    };

    const handlePrevPageClick = () => {
        setCurrentPage(prev => prev - 1);
    };

    const handleFirstPageClick = () => {
        setCurrentPage(1);
    };

    const handleLastPageClick = () => {
        setCurrentPage(
            Math.ceil(
                accountList.userAccountCount / parseInt(pageSize?.value ?? DefaultPageSize.value)
            )
        );
    };

    const handlePageSelect = (page: number) => {
        setCurrentPage(page);
    };

    const handleModalOpen = useCallback(
        (uid: string, userRole: number) => {
            dispatch(getUserAccountData(uid, userRole));
            setIsModalOpen(true);
        },
        [dispatch]
    );

    const handleModalClose = () => {
        setIsModalOpen(false);
        const size = parseInt(pageSize?.value ?? DefaultPageSize.value);
        debouncedSearch(
            currentPage,
            searchTerm,
            size,
            countyFilter,
            roleFilter,
            statusFilter,
            sortBy
        );
    };

    const handleActivateUser = (uid: string, userRole: number, isDeactivate: boolean) => {
        dispatch(deactivateUserByUid(uid, userRole, isDeactivate));
        setIsModalOpen(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(
            (
                currentPage: number,
                searchTerm: string | null,
                size: number,
                counties: number[],
                roles: number[],
                status: number,
                sortBy: SortingRule<object> | undefined
            ) =>
                dispatch(
                    getAccountList({
                        pageNumber: currentPage,
                        pageSize: size,
                        countyFilter: counties,
                        userRoleFilter: roles,
                        search: searchTerm,
                        statusFilter: status ?? -1,
                        sortField: sortBy?.id,
                        descending: sortBy?.desc,
                    })
                ),
            INPUT_DEBOUNCE_TIME
        ),
        []
    );

    useEffect(() => {
        const size = parseInt(pageSize?.value ?? DefaultPageSize.value);
        // api call is debounced, switch to loading earlier
        dispatch(getAccountListRequest());
        debouncedSearch(
            currentPage,
            searchTerm,
            size,
            countyFilter,
            roleFilter,
            statusFilter,
            sortBy
        );
    }, [
        countyFilter,
        currentPage,
        debouncedSearch,
        dispatch,
        pageSize?.value,
        roleFilter,
        searchTerm,
        statusFilter,
        sortBy,
        isModalOpen,
    ]);

    const tableColumns = useMemo(
        () => [
            {
                Header: "NAZIV KORISNIKA",
                id: "name",
                accessor: (data: any) => {
                    return (
                        <span>
                            {data.firstName} {data.lastName}
                        </span>
                    );
                },
            },
            {
                Header: "ULOGA",
                accessor: "userRole",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return <div className="role-row ">{original.userRole.label}</div>;
                },
            },
            {
                Header: "ŽUPANIJA",
                accessor: "county",
            },
            {
                Header: "MIBPG",
                accessor: "mibpg",
            },
            {
                Header: "OIB",
                accessor: "oib",
            },
            {
                Header: "GRAD",
                accessor: "city",
            },
            {
                Header: "EMAIL",
                accessor: "email",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return (
                        <span
                            className="email-link-cell
                    "
                        >
                            {original.email}
                        </span>
                    );
                },
            },
            {
                Header: "STATUS",
                accessor: "isActive",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return <span>{original.isActive ? "Aktivan" : "Neaktivan"}</span>;
                },
            },
            {
                Header: "DATUM PROMJENE STATUSA",
                accessor: "lastStatusUpdate",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return <span>{(original.lastStatusUpdate ?? "-").substring(0, 10)}</span>;
                },
            },
            {
                Header: "DATUM ZADNJE PRIJAVE",
                accessor: "lastLogin",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return <span>{(original.lastLogin ?? "-").substring(0, 10)}</span>;
                },
            },
            {
                id: "info",
                disableSortBy: true,
                Cell: ({ row }: any) => {
                    const { original } = row;
                    const type = findUserRoleByLabel(original.userRole);
                    return (
                        <DetailsIcon
                            className="details-icon"
                            onClick={() => handleModalOpen(original.uid, type?.value ?? 0)}
                        />
                    );
                },
            },
        ],
        [handleModalOpen]
    );

    return (
        <div className="user-administration">
            <div className="user-administration-heading">
                <div className="user-administration-filters">
                    <span>KORISNICI</span>
                    <AdministrationFilters
                        roleFilter={roleFilter}
                        setRoleFilter={setRoleFilter}
                        countyFilter={countyFilter}
                        setCountyFilter={setCountyFilter}
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
                <Input
                    name="search"
                    className="search"
                    id="search"
                    type="text"
                    size="small"
                    placeholder="Pretraži"
                    isValid={true}
                    onChange={handleSearchChange}
                    errorMessage=""
                    rightIcon={<SearchIcon className="search-icon" />}
                />
            </div>

            {accountList.isFetchingAccountList ? (
                <Loading />
            ) : accountList.data && !accountList.errorMessage ? (
                <>
                    <div className="table-container">
                        <CustomTable
                            data={accountList.data}
                            columns={tableColumns}
                            setSortBy={setSortBy}
                            tableType={TableType.AdministrationTable}
                        />
                    </div>
                    {accountList.data.length === 0 ? null : (
                        <Pagination
                            className="mt-24"
                            currentPage={currentPage}
                            numberOfPages={Math.ceil(
                                accountList.userAccountCount /
                                    parseInt(pageSize?.value ?? DefaultPageSize.value)
                            )}
                            pageNeighbours={PAGE_NEIGHBOURS}
                            pageSize={pageSize ?? DefaultPageSize}
                            onPageSizeChange={handlePageSizeChange}
                            handlepageClick={handlePageClick}
                            next={handleNexPageClick}
                            prev={handlePrevPageClick}
                            first={handleFirstPageClick}
                            last={handleLastPageClick}
                            handlePageSelect={handlePageSelect}
                        />
                    )}
                </>
            ) : (
                <p>{accountList.errorMessage}</p>
            )}
            <Modal
                isActive={isModalOpen}
                onClose={handleModalClose}
                modalHeader={
                    <ModalHeader
                        onClose={handleModalClose}
                        title={`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}
                        isLoading={userAccount.isFetchingUserData || !userData}
                    />
                }
            >
                {userAccount.isFetchingUserData || !userData ? (
                    <UserInfoLoading />
                ) : (
                    <UserInfo userInfo={userData} onDeactivateClick={handleActivateUser} />
                )}
            </Modal>
        </div>
    );
};
