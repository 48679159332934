import { Button, CustomTable, OptionType, Pagination, TableType } from "modules/common";
import { CodebookType } from "modules/common/constants/codebookType";
import { useMemo, useState } from "react";
import { SortingRule } from "react-table";
import { CodebookEntry, CodebookEntryResponse } from "services/codebooks";
import { CodebookDetailsModal } from "./CodebooksModal";
import { useDeactivateCodebookEntry } from "../hooks/codebooksHooks";
import { ConfirmationModal } from "modules/common/components/ConfirmationModal";

export interface ICodebooksTable {
    codebooks?: CodebookEntryResponse;
    isLoading?: boolean;
    setSortBy?: (sortBy: SortingRule<object>) => void;
    page?: number;
    pageSize?: number;
    onPageSizeChange?: (value: number) => void;
    onPageChange?: (page: number) => void;
    className?: string;
    codebookType: CodebookType;
}

const DefaultPageSize = {
    value: "10",
    label: "10",
};

const defaultTableColumns = (codebookType: CodebookType) => {
    const columnArray = [];

    columnArray.push({
        Header: "NAZIV",
        accessor: "name",
        width: 3,
        Cell: ({ row }: any) => {
            const { original } = row;
            return <span className="modal-name">{original.name}</span>;
        },
    });

    if (codebookType === CodebookType.Intervention) {
        columnArray.push({
            Header: "KOD",
            accessor: "code",
            width: 3,
            Cell: ({ row }: any) => {
                const { original } = row;
                return <span className="modal-name">{original.code}</span>;
            },
        });
        columnArray.push({
            Header: "CILJNA GRUPA",
            accessor: "targetGroup",
            width: 3,
            Cell: ({ row }: any) => {
                const { original } = row;
                return <span className="modal-name">{original.targetGroup}</span>;
            },
        });
    }

    if (codebookType === CodebookType.ProductionType) {
        columnArray.push({
            Header: "STRUČNO PODRUČJE",
            accessor: "professionalArea",
            width: 3,
            Cell: ({ row }: any) => {
                const { original } = row;
                return <span className="modal-name">{original.professionalAreaName}</span>;
            },
        });
    }

    return columnArray;
};

export const CodebooksTable = ({
    codebooks,
    isLoading,
    setSortBy,
    page,
    pageSize,
    onPageSizeChange,
    onPageChange,
    className,
    codebookType,
}: ICodebooksTable) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<CodebookEntry>();
    const deactivate = useDeactivateCodebookEntry();
    const [showDeactivate, setShowDeactivate] = useState(false);

    const tableColumns = useMemo(() => {
        let columns = defaultTableColumns(codebookType);
        if (!setSortBy) {
            columns = columns.map(column => ({ ...column, disableSortBy: true }));
        }

        const actionColumn = {
            Header: "",
            accessor: "info",
            id: "info",
            disableSortBy: true,
            width: 2,
            className: "actions-cell",
            Cell: ({ row }: any) => {
                return (
                    <>
                        <Button
                            type="button"
                            size="small"
                            variant="primary-ghost"
                            isDisabled={false}
                            onClick={() => {
                                setModalOpen(true);
                                setSelectedRow(row.original);
                            }}
                        >
                            <span>Uredi</span>
                        </Button>
                        <Button
                            type="button"
                            size="small"
                            variant={row.original.isActive ? "warning-ghost" : "secondary"}
                            isDisabled={false}
                            onClick={() => {
                                setSelectedRow(row.original);
                                setShowDeactivate(true);
                            }}
                        >
                            <span>{row.original.isActive ? "Deaktiviraj" : "Aktiviraj"}</span>
                        </Button>
                    </>
                );
            },
        };
        columns = [...columns, actionColumn];
        return columns;
    }, [codebookType, setSortBy]);

    const activePageSize = useMemo(() => {
        return pageSize
            ? { value: pageSize.toString(), label: pageSize.toString() }
            : DefaultPageSize;
    }, [pageSize]);

    const classNameString = useMemo(() => {
        return `codebooks-table ${className ?? ""}`;
    }, [className]);

    const handlePageSizeChange = (option: OptionType | null) => {
        onPageSizeChange && onPageSizeChange(parseInt(option?.value ?? DefaultPageSize.value));
    };

    const handlePageClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const target = event.currentTarget ?? event.target;
        onPageChange && onPageChange(Number(parseInt(target.id)));
    };

    const handleNexPageClick = () => {
        onPageChange && onPageChange(page ? page + 1 : 1);
    };

    const handlePrevPageClick = () => {
        onPageChange && onPageChange(page ? page - 1 : 1);
    };

    const onDeactivate = async () => {
        if (!selectedRow) {
            return;
        }
        await deactivate.mutateAsync({
            codebookGuid: selectedRow?.guid ?? "",
            codebookType: codebookType,
        });
        setShowDeactivate(false);
    };

    return (
        <div className={classNameString}>
            <CustomTable
                flex
                data={codebooks?.items ?? []}
                columns={tableColumns}
                loading={isLoading}
                setSortBy={setSortBy}
                tableType={TableType.AdministrationTable}
            />

            {onPageChange && codebooks?.items && codebooks.items.length > 0 && (
                <Pagination
                    className="mt-24"
                    currentPage={page ?? 1}
                    numberOfPages={Math.ceil(codebooks.total / parseInt(activePageSize.value))}
                    pageNeighbours={1}
                    pageSize={activePageSize}
                    onPageSizeChange={handlePageSizeChange}
                    handlepageClick={handlePageClick}
                    next={handleNexPageClick}
                    prev={handlePrevPageClick}
                />
            )}

            <CodebookDetailsModal
                isOpen={modalOpen}
                handleClose={() => setModalOpen(false)}
                codebook={selectedRow}
                codebookType={codebookType}
            />
            <ConfirmationModal
                isActive={showDeactivate}
                onConfirm={onDeactivate}
                onCancel={() => setShowDeactivate(false)}
                content={`Želite li ${selectedRow?.isActive ? "de" : ""}aktivirati unos ${
                    selectedRow?.name
                }?`}
            />
        </div>
    );
};
