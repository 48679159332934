enum ProfessionalField {
    Hortikultura = 0x000001,

    Ratarstvo = 0x000002,

    Stocarstvo = 0x000004,

    Pcelarstvo = 0x000008,

    Agroekologija = 0x000020,

    ZastitaBilja = 0x000040,

    UpravljanjePG = 0x000080,

    DopunskeDjelatnosti = 0x000100,

    MehanizacijaIInovativneTehnologije = 0x000200,
}

export const ProfessionalFieldFilterFields: Record<string, { value: number; label: string }> = {
    Hortikultura: {
        label: "Hortikultura",
        value: ProfessionalField.Hortikultura,
    },
    Ratarstvo: { value: ProfessionalField.Ratarstvo, label: "Ratarstvo" },
    Stocarstvo: {
        value: ProfessionalField.Stocarstvo,
        label: "Stočarstvo",
    },
    Pcelarstvo: { value: ProfessionalField.Pcelarstvo, label: "Pčelarstvo" },
    Agroekologija: { value: ProfessionalField.Agroekologija, label: "Agroekologija" },
    ZastitaBilja: {
        value: ProfessionalField.ZastitaBilja,
        label: "Zaštita bilja",
    },
    UpravljanjePG: {
        value: ProfessionalField.UpravljanjePG,
        label: "Upravljanje PG-om",
    },
    DopunskeDjelatnosti: {
        value: ProfessionalField.DopunskeDjelatnosti,
        label: "Dopunske djelatnosti",
    },
    MehanizacijaIInovativneTehnologije: {
        value: ProfessionalField.MehanizacijaIInovativneTehnologije,
        label: "Mehanizacija i inovativne tehnologije",
    },
};
