import { useTitle } from "modules/common";
import { Overlay } from "modules/common/components/Overlay";

export const SemisAdministration = () => {
    useTitle("Administracija PG-ova - eSavjetnik");
    return (
        <Overlay
            selectFirstMenuItemWhenAvailable
            titleBar={{ text: "Administracija PG-ova" }}
            fullScreenInitially={true}
            view={{
                title: "Administracija PG-ova",
                source: "https://semis.mps.hr/yiisemis/index.php?r=apprrrPgList/admin",
            }}
        />
    );
};

export default SemisAdministration;
