import { xor } from "lodash";
import { Button, CountyFilter, FilterGroup, useOutsideClick } from "modules/common";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getExpertAreas } from "..";
import { ReactComponent as FilterClearIcon } from "assets/icons/filter-clear.svg";
import {
    useGetFilterPreferencesQuery,
    useUpdateFilterPreferences,
} from "modules/common/hooks/filterPreferencesHooks";
import { ExpertAreasFilter } from "modules/common/components/ExpertAreasFilter";
import { add } from "date-fns";

export type DateRangeFilter = {
    from: Date;
    to: Date;
};

type FilterPreferencesPayload = {
    filterName: string;
    filterIntValue?: number;
    filterStringValue?: string;
    filterBoolValue?: boolean;
    clearFilter?: boolean;
};

interface Props {
    countyFilter: number[];
    setCountyFilter: React.Dispatch<React.SetStateAction<number[]>>;
    expertAreasFilter: string[];
    setExpertAreasFilter: React.Dispatch<React.SetStateAction<string[]>>;
    isMandatoryFilter: boolean;
    setIsMandatoryFilter: React.Dispatch<React.SetStateAction<boolean>>;
    isOupFilter: boolean;
    setIsOupFilter: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setDate: React.Dispatch<React.SetStateAction<DateRangeFilter>>;
    date: DateRangeFilter;
}

export const EducationFilters: React.FC<Props> = ({
    countyFilter,
    setCountyFilter,
    expertAreasFilter,
    setExpertAreasFilter,
    isMandatoryFilter,
    setIsMandatoryFilter,
    isOupFilter,
    setIsOupFilter,
    setCurrentPage,
    setDate,
    date,
}) => {
    const filterPreferencesQuery = useGetFilterPreferencesQuery([
        `${EducationFilters.name}-countyFilter`,
        `${EducationFilters.name}-expertAreasFilter`,
        `${EducationFilters.name}-isMandatoryFilter`,
        `${EducationFilters.name}-isOupFilter`,
    ]);
    const updateFilterPreferencesMutation = useUpdateFilterPreferences();

    const dispatch = useDispatch();
    const countiesFilterRef = useRef<HTMLDivElement>(null);
    const expertAreasFilterRef = useRef<HTMLDivElement>(null);

    const { items: ReducerExpertAreas, isLoading: loadingExpertAreas } = useSelector(
        (state: RootState) => state.expertAreas
    );

    const [showCountyFilter, setShowCountyFilter] = useState(false);
    const isCountryFilterActive = countyFilter.length > 0;
    const [showExpertAreasFilter, setShowExpertAreasFilter] = useState(false);
    const isExpertAreasFilterActive = expertAreasFilter.length > 0;

    const handleCountyFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const valueNumb = parseInt(value);
        setCountyFilter(prev => {
            if (!prev.includes(valueNumb)) {
                return [...prev, valueNumb];
            } else {
                return [...prev.filter(s => s !== valueNumb)];
            }
        });
        handleUpdateFilterPreferences(`${EducationFilters.name}-countyFilter`, valueNumb);
        setCurrentPage(1);
    };

    function handleExpertAreasFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
        const selectedExpertArea = ReducerExpertAreas?.find(i => i === event.target.value);

        if (!selectedExpertArea) {
            return;
        }

        // Create array of unique values
        const newArray = xor(expertAreasFilter, [selectedExpertArea]);
        setExpertAreasFilter(newArray);
        handleUpdateFilterPreferences(
            `${EducationFilters.name}-expertAreasFilter`,
            event.target.value
        );
        setCurrentPage(1);
    }

    const handleBoolFilterChange = (
        filterName: string,
        filterState: boolean,
        handleClear: () => void
    ) => {
        const newValue = !filterState;
        newValue
            ? handleUpdateFilterPreferences(`${EducationFilters.name}-${filterName}`, newValue)
            : handleClear();

        setCurrentPage(1);
    };

    const handleIsOupFilterChange = () => {
        handleBoolFilterChange("isOupFilter", isOupFilter, handleIsOupFilterClear);
    };

    const handleIsMandatoryFilterChange = () => {
        handleBoolFilterChange(
            "isMandatoryFilter",
            isMandatoryFilter,
            handleIsMandatoryFilterClear
        );
    };
    const handleCountyFilterOutsideClick = () => {
        setShowCountyFilter(false);
    };

    const handleExpertAreasFilterOutsideClick = () => {
        setShowExpertAreasFilter(false);
    };

    useOutsideClick(countiesFilterRef, handleCountyFilterOutsideClick);
    useOutsideClick(expertAreasFilterRef, handleExpertAreasFilterOutsideClick);

    const handleCountyFilterClear = () => {
        handleUpdateFilterPreferences(`${EducationFilters.name}-countyFilter`, undefined, true);
    };

    const handleExpertAreasFilterClear = () => {
        handleUpdateFilterPreferences(
            `${EducationFilters.name}-expertAreasFilter`,
            undefined,
            true
        );
    };

    const handleIsOupFilterClear = () => {
        handleUpdateFilterPreferences(`${EducationFilters.name}-isOupFilter`, undefined, true);
    };

    const handleIsMandatoryFilterClear = () => {
        setIsMandatoryFilter(false);
        handleUpdateFilterPreferences(
            `${EducationFilters.name}-isMandatoryFilter`,
            undefined,
            true
        );
    };

    const handleCountyFilterClick = () => {
        setShowCountyFilter(prev => !prev);
    };

    const handleExpertAreasFilterClick = (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setShowExpertAreasFilter(!showExpertAreasFilter);

        if (!ReducerExpertAreas.length) {
            dispatch(getExpertAreas());
        }
    };

    const handleClearAllFilters = () => {
        handleExpertAreasFilterClear();
        handleCountyFilterClear();
        handleIsOupFilterClear();
        handleIsMandatoryFilterClear();
        setDate({
            from: new Date(),
            to: add(new Date(), { months: 3 }),
        });
    };

    const handleUpdateFilterPreferences = (
        filterName: string,
        filterValue?: number | string | boolean,
        clearFilter?: boolean
    ) => {
        const payload: FilterPreferencesPayload = {
            filterName,
            clearFilter,
        };

        if (typeof filterValue === "number") {
            payload.filterIntValue = filterValue;
        } else if (typeof filterValue === "string") {
            payload.filterStringValue = filterValue;
        } else if (typeof filterValue === "boolean") {
            payload.filterBoolValue = filterValue;
        }

        updateFilterPreferencesMutation.mutate(payload);
    };

    useEffect(() => {
        if (filterPreferencesQuery.some(s => s.isLoading)) {
            return;
        }

        const countyFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${EducationFilters.name}-countyFilter`
        )?.data?.filterIntValues;

        const expertAreasFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${EducationFilters.name}-expertAreasFilter`
        )?.data?.filterStringValues;

        const isOupFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${EducationFilters.name}-isOupFilter`
        )?.data?.filterBoolValue;

        const isMandatoryFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${EducationFilters.name}-isMandatoryFilter`
        )?.data?.filterBoolValue;

        if (countyFilterData) {
            setCountyFilter(countyFilterData);
        }

        if (expertAreasFilterData) {
            setExpertAreasFilter(expertAreasFilterData);
        }

        setIsOupFilter(Boolean(isOupFilterData));
        setIsMandatoryFilter(Boolean(isMandatoryFilterData));
    }, [filterPreferencesQuery]);

    const shouldShowClearFilterIcon = useMemo(() => {
        return (
            countyFilter.length > 0 ||
            expertAreasFilter.length > 0 ||
            isMandatoryFilter ||
            isOupFilter ||
            (date.from &&
                date.to &&
                (date.from.toDateString() !== new Date().toDateString() ||
                    date.to.toDateString() !== add(new Date(), { months: 3 }).toDateString()))
        );
    }, [countyFilter, expertAreasFilter, isMandatoryFilter, isOupFilter, date]);

    return (
        <FilterGroup className="mr-32" text="">
            <div className="mr-2" ref={countiesFilterRef}>
                <CountyFilter
                    onChange={handleCountyFilterChange}
                    onClear={handleCountyFilterClear}
                    onClick={handleCountyFilterClick}
                    value={countyFilter}
                    isActive={isCountryFilterActive}
                    showDropdown={showCountyFilter}
                />
            </div>

            <div ref={expertAreasFilterRef}>
                <ExpertAreasFilter
                    onChange={handleExpertAreasFilterChange}
                    onClear={handleExpertAreasFilterClear}
                    onClick={handleExpertAreasFilterClick}
                    isActive={isExpertAreasFilterActive}
                    showDropdown={showExpertAreasFilter}
                    expertAreasFields={ReducerExpertAreas}
                    loadingExpertAreas={loadingExpertAreas}
                    expertAreasFilter={expertAreasFilter}
                />
            </div>

            <Button
                className={`mr-12 education-filter-toggle-button ${
                    isMandatoryFilter ? "education-filter-toggle-button-selected" : ""
                }`}
                onClick={() => {
                    setIsMandatoryFilter(!isMandatoryFilter);
                    handleIsMandatoryFilterChange();
                }}
                size="small"
                isDisabled={false}
            >
                OBAVEZA
            </Button>

            <Button
                className={`education-filter-toggle-button ${
                    isOupFilter ? "education-filter-toggle-button-selected" : ""
                }`}
                onClick={() => {
                    setIsOupFilter(!isOupFilter);
                    handleIsOupFilterChange();
                }}
                size="small"
                isDisabled={false}
            >
                OUP
            </Button>
            {shouldShowClearFilterIcon && (
                <FilterClearIcon
                    className="filter-group__icon clear-filter ml-16"
                    onClick={handleClearAllFilters}
                />
            )}
        </FilterGroup>
    );
};
