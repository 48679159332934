import { formErrorMessages, isValidMobile } from "modules/auth";
import { Button, Input, phoneRegex } from "modules/common";
import {
    getCounties,
    getSettlementsByCountyId,
    updateAccountMetadata,
    updateMobileNumber,
} from "modules/dashboard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CountyResponse, SettlementResponse } from "services";
import { RootState } from "store";
import { Dropdown } from "../Dropdown";
import { ProfileInfo } from "./ProfileInfo";

interface FormData {
    county: CountyResponse;
    settlement: SettlementResponse;
    postalNumber: string;
    street: string;
    streetNumber: string;
    mobileNumber: string;
}

interface Settlement {
    value: string;
    label: string;
}
const settlementInitialValue: SettlementResponse = {
    id: null,
    name: "",
    postOffice: "",
    postalNumber: "",
};

export const ProfileInfoCitizenForm: React.FC = () => {
    const dispatch = useDispatch();
    const { profile } = useSelector((state: RootState) => state.profile);
    const { counties, settlements, isLoading } = useSelector((state: RootState) => state.address);
    const [isFormChanged, setIsFormChanged] = useState(false);

    // Create model and update
    const [state, setState] = useState<FormData>({
        county: {
            id: Number(profile?.countyId) || null,
            name: profile?.county || "",
            valueName: profile?.county || "",
        },
        settlement: {
            id: Number(profile?.settlementId) || null,
            name: profile?.settlement || "",
            postOffice: "",
            postalNumber: profile?.postalNumber || "",
        },
        postalNumber: profile?.postalNumber || "",
        street: !!profile?.street.length ? `${profile?.street}` : "",
        streetNumber: !!profile?.streetNumber.length ? `${profile?.streetNumber}` : "",
        mobileNumber: profile?.mobileNumber || "",
    });
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});

    /**
     * Create options list for counties
     * Create one function for counties and settlements
     */
    const countyList = counties.map(county => {
        const item = {
            label: county.name,
            value: county.valueName,
        };

        return item;
    });

    /**
     * Create options list for settlements
     * Create one function for counties and settlements
     */
    const settlementList = settlements.map(settlement => {
        const item = {
            label: settlement.name,
            value: settlement.name,
        };

        return item;
    });

    /** Create one function for react-select onChange */
    function onCountyChange(data: { label: string; value: string } | null) {
        const selectedCounty = counties?.find(i => i.valueName === data?.value);

        if (!selectedCounty || !selectedCounty.id) {
            return;
        }

        setState({
            ...state,
            county: selectedCounty,
            settlement: settlementInitialValue,
            postalNumber: "",
        });

        dispatch(getSettlementsByCountyId(selectedCounty.id));
    }

    /** Create one function for react-select onChange */
    function onSettlementChange(data: { label: string; value: string } | null) {
        const selectedSettlement = settlements.find(i => i.name === data?.value);

        if (!selectedSettlement) {
            return;
        }

        setState({
            ...state,
            settlement: selectedSettlement,
            postalNumber: selectedSettlement.postalNumber,
        });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setState(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    function onSubmit() {
        if (!phoneRegex.test(state.mobileNumber) && !!state.mobileNumber.length) return;
        if (state.mobileNumber !== profile?.mobileNumber || !formErrors["mobileNumber"]) {
            dispatch(updateMobileNumber(state.mobileNumber, ""));
        }

        if (!state.county.id || !state.settlement.id) return;

        // Update with new metadata payload
        dispatch(
            updateAccountMetadata({
                countyId: state.county.id,
                postalNumber: state.settlement.postalNumber,
                settlementId: state.settlement.id,
                street: state.street,
                streetNumber: state.streetNumber,
            })
        );
    }

    useEffect(() => {
        if (!profile) return;

        const profileAddressCheck = `${profile.street
            .replaceAll(" ", "")
            .toLowerCase()}${profile.streetNumber.replaceAll(" ", "").toLowerCase()}`;

        if (
            state.street.replaceAll(" ", "").toLowerCase() !== profileAddressCheck ||
            state.streetNumber.replaceAll(" ", "").toLowerCase() ||
            state.county.name !== profile.county ||
            state.mobileNumber !== profile.mobileNumber ||
            state.postalNumber !== profile.postalNumber ||
            state.settlement.name !== profile.settlement
        ) {
            setIsFormChanged(true);
            return;
        }

        setIsFormChanged(false);
    }, [state]);

    return (
        <ProfileInfo>
            <label className="profile-settings-label" htmlFor="county">
                Županija
            </label>
            <div className="profile-settings-input">
                <Dropdown
                    isLoading={isLoading}
                    onMenuOpen={() => dispatch(getCounties())}
                    id="county"
                    maxMenuHeight={170}
                    size="large"
                    isValid={true}
                    errorMessage=""
                    noOptionsMessage={() => "Nema podataka"}
                    options={countyList}
                    isSearchable
                    onChange={onCountyChange}
                    placeholder=""
                    value={{
                        value: state.county.name,
                        label: state.county.name.toUpperCase(),
                    }}
                />
            </div>
            <label className="profile-settings-label" htmlFor="settlement">
                Mjesto
            </label>
            <div className="profile-settings-input">
                <Dropdown
                    key={state.county.valueName}
                    isLoading={isLoading}
                    id="settlement"
                    maxMenuHeight={170}
                    size="large"
                    isValid={true}
                    errorMessage="Molimo odaberite mjesto"
                    noOptionsMessage={() => "Molimo odaberite mjesto"}
                    options={settlementList.sort((a: Settlement, b: Settlement) => {
                        return a.label.localeCompare(b.label);
                    })}
                    onChange={onSettlementChange}
                    isSearchable
                    placeholder=""
                    value={{ value: state.settlement.name, label: state.settlement.name }}
                />
            </div>
            <label className="profile-settings-label" htmlFor="zipCode">
                Poštanski broj
            </label>
            <div className="profile-settings-input">
                <Input
                    readOnly
                    isDisabled
                    id="zipCode"
                    type="text"
                    size="large"
                    isValid={!!state.postalNumber.length || true}
                    name="zipCode"
                    value={state.postalNumber}
                    errorMessage=""
                    onChange={handleInputChange}
                />
            </div>
            <label className="profile-settings-label" htmlFor="address">
                Ulica
            </label>
            <div className="profile-settings-input">
                <Input
                    id="street"
                    type="text"
                    size="large"
                    isValid={!!state.street.length || true}
                    name="street"
                    errorMessage=""
                    onChange={handleInputChange}
                    value={state.street}
                />
            </div>
            <label className="profile-settings-label" htmlFor="address">
                Kućni broj
            </label>
            <div className="profile-settings-input">
                <Input
                    id="streetNumber"
                    type="text"
                    size="large"
                    isValid={!!state.streetNumber.length || true}
                    name="streetNumber"
                    errorMessage=""
                    onChange={handleInputChange}
                    value={state.streetNumber}
                />
            </div>
            <label className="profile-settings-label" htmlFor="phone">
                Kontakt broj
            </label>
            <div className="profile-settings-input">
                <Input
                    id="mobileNumber"
                    type="text"
                    size="large"
                    isValid={phoneRegex.test(state.mobileNumber) || !state.mobileNumber.length}
                    name="mobileNumber"
                    errorMessage=""
                    onChange={handleInputChange}
                    value={state.mobileNumber}
                />
            </div>
            <div className="profile-settings-button ">
                <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    isDisabled={!isFormChanged}
                    onClick={onSubmit}
                >
                    <span>Spremi izmjene</span>
                </Button>
            </div>
        </ProfileInfo>
    );
};
