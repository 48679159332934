import { CountyFilterFields } from "./counties";
import { InfoType, infoTypes } from "./infoTypes";
import { NotificationType } from "./notificationTypes";
import { ProfessionalFieldFilterFields } from "./professionalFieldTypes";

export enum NotificationSubscriptionCategory {
    InfoTypeSubscription = "info-type",
    NotificationTypeSubscription = "notification-type",
    PlantProtectionCountySubscription = "plant-protection-county",
    NewsProfessionalFieldSubscription = "news-professional-field",
    ManifestationsCountySubscription = "manifestations-county",
    MainfestationsProfessionalFieldSubscription = "manifestations-professional-field",
}

export type Subscription = {
    id: number;
    category: NotificationSubscriptionCategory;
    title: string;
};

export type ProfileSubscriptionSection = {
    subscriptions: Array<Subscription>;
    groupTitle?: string;
    subgroups?: ProfileSubscriptionSection[];
};

export const NotificationSettingsSection = [
    {
        subscriptions: [
            {
                id: NotificationType.Email,
                category: NotificationSubscriptionCategory.NotificationTypeSubscription,
                title: "Email (primarno)",
            },
            {
                id: NotificationType.Push,
                category: NotificationSubscriptionCategory.NotificationTypeSubscription,
                title: "Aplikacija",
            },
        ],
    },
] as Array<ProfileSubscriptionSection>;

export const NotificationSubscriptionsSection = [
    {
        subscriptions: [
            {
                id: InfoType.WeatherAlarm,
                category: NotificationSubscriptionCategory.InfoTypeSubscription,
                title: infoTypes.WeatherAlarm.label,
            },
        ],
    },
    {
        subscriptions: [
            {
                id: InfoType.News,
                category: NotificationSubscriptionCategory.InfoTypeSubscription,
                title: infoTypes.News.label,
            },
            {
                id: InfoType.Advice,
                category: NotificationSubscriptionCategory.InfoTypeSubscription,
                title: infoTypes.Advice.label,
            },
        ],
        groupTitle: "Vijesti i savjeti",
        subgroups: [
            {
                subscriptions: Object.values(ProfessionalFieldFilterFields).map(field => ({
                    id: field.value,
                    category: NotificationSubscriptionCategory.NewsProfessionalFieldSubscription,
                    title: field.label,
                })),

                groupTitle: "Stručna područja",
            },
        ],
    },
    {
        subscriptions: [
            {
                id: InfoType.CoursesNotification,
                category: NotificationSubscriptionCategory.InfoTypeSubscription,
                title: infoTypes.CoursesNotification.label,
            },
        ],
        groupTitle: "Edukacije",
    },
    {
        subscriptions: [
            {
                id: InfoType.WineGrowing,
                category: NotificationSubscriptionCategory.InfoTypeSubscription,
                title: "Vinogradarstvo",
            },
            {
                id: InfoType.FruitAndOliveGrowing,
                category: NotificationSubscriptionCategory.InfoTypeSubscription,
                title: "Voćarstvo i maslinarstvo",
            },
            {
                id: InfoType.FarmingAndOther,
                category: NotificationSubscriptionCategory.InfoTypeSubscription,
                title: "Ratarstvo i ostalo",
            },
        ],
        groupTitle: "Preporuke za zaštitu bilja",
        subgroups: [
            {
                subscriptions: Object.values(CountyFilterFields).map(county => ({
                    id: county.value,
                    category: NotificationSubscriptionCategory.PlantProtectionCountySubscription,
                    title: county.label,
                })),

                groupTitle: "Županije",
            },
        ],
    },
    {
        subscriptions: [
            {
                id: InfoType.Manifestations,
                category: NotificationSubscriptionCategory.InfoTypeSubscription,
                title: "Manifestacije",
            },
        ],
        groupTitle: "Manifestacije",
        subgroups: [
            {
                subscriptions: Object.values(CountyFilterFields).map(county => ({
                    id: county.value,
                    category: NotificationSubscriptionCategory.ManifestationsCountySubscription,
                    title: county.label,
                })),

                groupTitle: "Županije",
            },
            {
                subscriptions: Object.values(ProfessionalFieldFilterFields).map(field => ({
                    id: field.value,
                    category:
                        NotificationSubscriptionCategory.MainfestationsProfessionalFieldSubscription,
                    title: field.label,
                })),

                groupTitle: "Stručna područja",
            },
        ],
    },
] as Array<ProfileSubscriptionSection>;
