import { useEffect, useMemo } from "react";
import { useTable, useSortBy, SortingRule, useFlexLayout, PluginHook } from "react-table";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { Loading, NoResults } from "modules/common";

export enum TableType {
    EducationsTable,
    AdministrationTable,
}

interface Props {
    data: Array<any>;
    columns: any;
    loading?: boolean;
    flex?: boolean;
    setSortBy?: (sortBy: SortingRule<object>) => void;
    tableType: TableType;
}
export const CustomTable: React.FC<Props> = ({
    data,
    columns,
    loading,
    flex,
    setSortBy,
    tableType,
}) => {
    const tablePlugins = useMemo(() => {
        const plugins: PluginHook<object>[] = [useSortBy];
        if (flex) plugins.push(useFlexLayout);
        return plugins;
    }, [flex]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            manualSortBy: true,
        },
        ...tablePlugins
    );

    useEffect(() => {
        if (setSortBy) {
            setSortBy(sortBy[0]);
        }
    }, [sortBy, setSortBy]);

    if (loading) {
        return <Loading />;
    }

    return (
        <table className={`table ${flex ? "flex" : ""}`} {...getTableProps()}>
            <TableHeader headerGroups={headerGroups} sortBy={sortBy?.[0]} tableType={tableType} />
            {data.length === 0 ? (
                <tbody>
                    <tr>
                        <td colSpan={4}>
                            <NoResults className="mt-24" />
                        </td>
                    </tr>
                </tbody>
            ) : (
                <TableBody
                    rows={rows}
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}
                    tableType={tableType}
                />
            )}
        </table>
    );
};
