import React from "react";
import { Can } from "modules/auth";

import { EducationSignup } from "modules/education";
import { useLocation } from "react-router-dom";
import { useTitle } from "modules/common";

export const Educations: React.FC = () => {
    useTitle("Edukacija - eSavjetnik");
    const { search } = useLocation();

    return (
        <Can I="view" a="EducationModule">
            <div className="educations-container">
                <Can I="view" a="CourseRegistration">
                    <EducationSignup />
                </Can>
            </div>
        </Can>
    );
};
