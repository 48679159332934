enum ActionType {
    UserLogin = 0x001,
    UserLogout = 0x002,
    UserRegistration = 0x004,
    UserRegistrationConfirmation = 0x008,
    UserDeactivation = 0x010,
    UserSelfDeactivation = 0x020,
    SendMessage = 0x040,
    ChangeInfoType = 0x080,
    ChangeNotificationType = 0x100,
    AddStation = 0x200,
    ChangeStation = 0x400,
    AddBoundryValues = 0x800,
    ChangeBoundryValues = 0x1000,
    AddCulture = 0x2000,
    DeleteCulture = 0x4000,
    UploadDocument = 0x8000,
    DeactivateDocument = 0x10000,
}

export const actionTypes: Record<string, { value: number; label: string }> = {
    UserLogin: { value: ActionType.UserLogin, label: "Prijava korisnika" },
    UserLogout: { value: ActionType.UserLogout, label: "Odjava korisnika" },
    UserRegistration: { value: ActionType.UserRegistration, label: "Registracija korisnika" },
    UserRegistrationConfirmation: {
        value: ActionType.UserRegistrationConfirmation,
        label: "Potvrda registracije korisnika",
    },
    UserDeactivation: { value: ActionType.UserDeactivation, label: "Deaktivacija korisnika" },
    UserSelfDeactivation: {
        value: ActionType.UserSelfDeactivation,
        label: "Samo-deaktivacija korisnika",
    },
    SendMessage: { value: ActionType.SendMessage, label: "Slanje poruke" },
    ChangeInfoType: {
        value: ActionType.ChangeInfoType,
        label: "Promjena predbilježbe",
    },
    ChangeNotificationType: {
        value: ActionType.ChangeNotificationType,
        label: "Promjena načina obavještavanja",
    },
    AddStation: { value: ActionType.AddStation, label: "Unos stanice" },
    ChangeStation: { value: 20, label: "Promjena stanice" },
    ChangeBoundryValues: {
        value: ActionType.ChangeBoundryValues,
        label: "Promjena graničnih vrijednosti",
    },
    AddCulture: { value: ActionType.AddCulture, label: "Unos kulture" },
    UploadDocument: {
        value: ActionType.UploadDocument,
        label: "Učitan dokument",
    },
    DeactivateDocument: {
        value: ActionType.DeactivateDocument,
        label: "Deaktiviran dokument",
    },
};
