export enum DeadlineType {
    Unkown = 0,
    Overdue = 1,
    Tomorrow = 2,
    In3Days = 3,
    In5Days = 4,
    In10Days = 5,
    MoreThanAMonth = 6,
}

export const deadlineTypes: Record<string, { value: number; label: string }> = {
    Unknown: {
        value: DeadlineType.Unkown,
        label: "Nepoznato",
    },
    Overdue: {
        value: DeadlineType.Overdue,
        label: "Istekao",
    },
    Tomorrow: {
        value: DeadlineType.Tomorrow,
        label: "Sutra",
    },
    In3Days: {
        value: DeadlineType.In3Days,
        label: "Za 3 dana",
    },
    In5Days: {
        value: DeadlineType.In5Days,
        label: "Za 5 dana",
    },
    In10Days: {
        value: DeadlineType.In10Days,
        label: "Za 10 dana",
    },
    MoreThanAMonth: {
        value: DeadlineType.MoreThanAMonth,
        label: "Više od mjesec dana",
    },
};
