import { userRoles } from "modules/common/constants";
import React from "react";
import { Filter, Checkbox } from "../";

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    isActive: boolean;
    showDropdown: boolean;
    value: number[];
}

export type RoleFilterState = string[];

const rolesToRender = [
    userRoles.Citizen,
    userRoles.Farmer,
    userRoles.MinistryOfficial,
    userRoles.MinistryAdviser,
    userRoles.SuperAdmin,
    userRoles.PrivateAdviser,
    userRoles.CommunicatorAdviser,
    userRoles.EquipmentAdmin,
    userRoles.CommunicatorOfficial,
];

export const RoleFilter: React.FC<Props> = ({
    onChange,
    onClick,
    onClear,
    isActive,
    showDropdown,
    value,
}) => {
    return (
        <Filter
            isActive={isActive}
            showDropdown={showDropdown}
            onClear={onClear}
            onClick={onClick}
            text="ULOGA"
        >
            {rolesToRender.map((role, index) => (
                <div key={index}>
                    <Checkbox
                        type="checkbox"
                        value={role.value.toString()}
                        checked={value.includes(role.value)}
                        onChange={onChange}
                        htmlFor={role.label}
                        text={
                            <div className="filter__item__text">
                                {role.label.toLocaleUpperCase()}
                            </div>
                        }
                    />
                </div>
            ))}
        </Filter>
    );
};
