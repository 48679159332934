import { useTitle } from "modules/common";
import { Overlay } from "modules/common/components/Overlay";
import { PanelMenuItem } from "modules/common/components/PanelMenu";

const menuItems: PanelMenuItem[] = [
    {
        label: "Analiza Edukacija PG-a",
        link: "https://semis.mps.hr/yiisemis/index.php?r=apprrrPgList/admin",
    },
    {
        label: "Kalendar Edukacija",
        link: "https://semis.mps.hr/yiisemis/index.php?r=karticaTecaja/courseCalendarView",
    },
];

const EducationAnalysis = () => {
    useTitle("Analiza edukacija - eSavjetnik");
    return (
        <Overlay
            menuItems={menuItems}
            selectFirstMenuItemWhenAvailable
            titleBar={{
                fullScreenButton: true,
                newWindowButton: true,
            }}
            view={{
                title: "Analiza edukacija",
                source: item => item?.link ?? "",
            }}
        />
    );
};

export default EducationAnalysis;
