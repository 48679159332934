import {
    Button,
    DisplayOrInput,
    FingerprintDisplay,
    Input,
    Modal,
    ModalFooter,
    ModalHeader,
    RoleSelector,
    ToggleSwitch,
} from "modules/common";
import { ConfirmationModal } from "modules/common/components/ConfirmationModal";
import { useEffect, useMemo, useState } from "react";
import { OuterModule } from "services";
import { useCreateOuterModule, useDeleteOuterModule, useEditOuterModule } from "..";

export interface IOuterModuleDetailsModal {
    module?: OuterModule;
    totalModules: number;
    isOpen: boolean;
    handleClose: () => void;
}

const emptyModule: OuterModule = {
    id: "",
    name: "",
    description: "",
    url: "",
    roles: [],
    isActive: true,
    orderNumber: 1,
    createdBy: "",
    created: "",
    lastUpdatedBy: "",
    lastUpdated: "",
    isPublic: false,
};

type ModuleProperty = keyof OuterModule;

type ModuleErrors = Partial<Record<ModuleProperty, string>>;

const valudateModule = (module: OuterModule, maxOrderNumber: number): ModuleErrors => {
    const errors: ModuleErrors = {};
    if (!module.name) errors.name = "Naziv modula je obavezan";
    if (!module.url) errors.url = "URL modula je obavezan";
    if (!module.url.match(/^(http|https):\/\/[^ "]+$|^(www)\.[^ "]+$/))
        errors.url = "URL nije ispravnog formata";
    if (!module.orderNumber) errors.orderNumber = "Redni broj modula je obavezan";
    else if (module.orderNumber < 1 || module.orderNumber > maxOrderNumber)
        errors.orderNumber = `Redni broj modula mora biti između 1 i ${maxOrderNumber}`;
    if (!module.isPublic && !module.roles.length)
        errors.roles = "Uloge su obavezne ako modul nije javan";

    return errors;
};

export const OuterModuleDetailsModal = ({
    module: selectedModule,
    totalModules,
    isOpen,
    handleClose,
}: IOuterModuleDetailsModal) => {
    const [module, setModule] = useState<OuterModule>(emptyModule);
    const [errors, setErrors] = useState<ModuleErrors>({});
    const [isEdit, setIsEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const create = useCreateOuterModule();
    const edit = useEditOuterModule();
    const remove = useDeleteOuterModule();

    useEffect(() => {
        setModule(selectedModule ?? emptyModule);
    }, [selectedModule, setModule]);

    useEffect(() => {
        setErrors({});
        setIsEdit(false);
        if (!isOpen) setModule(emptyModule);
    }, [isOpen, setErrors, setIsEdit]);

    const isAdd = useMemo(() => !selectedModule, [selectedModule]);

    const maxOrderNumber = useMemo(
        () => (isAdd ? totalModules + 1 : totalModules),
        [isAdd, totalModules]
    );

    const title = useMemo(() => {
        if (isAdd) return "Novi modul";
        if (isEdit) return "Uredi modul";
        return module?.name || "Detalji modula";
    }, [isAdd, isEdit, module?.name]);

    const changeModuleProperty = (
        property: ModuleProperty,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setErrors(prevState => ({ ...prevState, [property]: undefined }));
        setModule(prevState => ({ ...(prevState ?? emptyModule), [property]: event.target.value }));
    };

    const isValid = (property: ModuleProperty) => {
        return !errors[property];
    };

    const getErrorMsg = (property: ModuleProperty) => {
        return errors[property] ?? "";
    };

    const onCancel = () => {
        setIsEdit(false);
        setErrors({});
        const module = selectedModule ?? emptyModule;
        setModule(module);
    };

    const onSave = async () => {
        const newErrors = valudateModule(module, maxOrderNumber);
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }

        const mutation = isAdd ? create.mutateAsync : edit.mutateAsync;
        await mutation(module);
        handleClose();
    };

    const onDelete = async () => {
        if (!module) return;
        await remove.mutateAsync(module.id);
        setShowDelete(false);
        handleClose();
    };

    return (
        <Modal
            isActive={isOpen}
            className="outer-module-details-modal"
            onClose={handleClose}
            modalHeader={<ModalHeader onClose={handleClose} title={title} />}
            modalFooter={
                !module ? null : (
                    <ModalFooter>
                        {isAdd || isEdit ? (
                            <>
                                {isEdit && (
                                    <Button
                                        type="button"
                                        size="small"
                                        variant="primary-ghost"
                                        isDisabled={false}
                                        onClick={onCancel}
                                    >
                                        <span>ODUSTANI</span>
                                    </Button>
                                )}
                                <Button
                                    type="button"
                                    size="small"
                                    variant="primary"
                                    isDisabled={false}
                                    onClick={onSave}
                                >
                                    <span>SPREMI</span>
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    type="button"
                                    size="small"
                                    variant="warning-ghost"
                                    isDisabled={false}
                                    onClick={() => setShowDelete(true)}
                                >
                                    <span>OBRIŠI</span>
                                </Button>
                                <Button
                                    type="button"
                                    size="small"
                                    variant="primary-ghost"
                                    isDisabled={false}
                                    onClick={() => setIsEdit(true)}
                                >
                                    <span>UREDI</span>
                                </Button>
                            </>
                        )}
                    </ModalFooter>
                )
            }
        >
            <div className="outer-module-details">
                <div className="modal-section narrow">
                    <span className="modal-details-label">Naziv modula</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {module?.name}
                            <Input
                                id="name"
                                type="text"
                                size="small"
                                name="name"
                                isValid={isValid("name")}
                                value={module?.name}
                                errorMessage={getErrorMsg("name")}
                                onChange={changeModuleProperty.bind(null, "name")}
                            />
                        </DisplayOrInput>
                    </span>
                </div>
                <div className="modal-section narrow">
                    <span className="modal-details-label">Redni broj</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {module?.orderNumber}
                            <Input
                                id="orderNumber"
                                type="number"
                                size="small"
                                name="orderNumber"
                                errorMessage={getErrorMsg("orderNumber")}
                                isValid={isValid("orderNumber")}
                                min={1}
                                max={maxOrderNumber}
                                value={module?.orderNumber}
                                onChange={changeModuleProperty.bind(null, "orderNumber")}
                            />
                        </DisplayOrInput>
                    </span>
                </div>
                <div className="modal-section narrow">
                    <span className="modal-details-label">Status</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {module?.isActive ? "Aktivan" : "Neaktivan"}
                            <ToggleSwitch
                                value={Boolean(module?.isActive).toString()}
                                checked={module?.isActive}
                                onChange={() =>
                                    setModule(prevState => ({
                                        ...prevState,
                                        isActive: !prevState.isActive,
                                    }))
                                }
                                htmlFor="status"
                                valueNames={{ true: "Aktivan", false: "Neaktivan" }}
                            />
                        </DisplayOrInput>
                    </span>
                </div>
                <div className="modal-section wide">
                    <span className="modal-details-label">Opis modula</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {module?.description}
                            <Input
                                isArea
                                id="description"
                                type="text"
                                size="small"
                                name="description"
                                errorMessage=""
                                isValid={true}
                                value={module?.description}
                                onChange={changeModuleProperty.bind(null, "description")}
                            />
                        </DisplayOrInput>
                    </span>
                </div>
                <div className="modal-section wide">
                    <span className="modal-details-label">URL</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            <a href={module?.url}>{module?.url}</a>
                            <Input
                                id="url"
                                type="text"
                                size="small"
                                name="url"
                                errorMessage={getErrorMsg("url")}
                                isValid={isValid("url")}
                                value={module?.url}
                                onChange={changeModuleProperty.bind(null, "url")}
                            />
                        </DisplayOrInput>
                    </span>
                </div>
                <div className="modal-section wide">
                    <span className="modal-details-label">JAVNO DOSTUPNO</span>
                    <span className="modal-details-text">
                        <DisplayOrInput isEdit={isAdd || isEdit}>
                            {module?.isPublic ? "Da" : "Ne"}
                            <ToggleSwitch
                                value={Boolean(module?.isPublic).toString()}
                                checked={module?.isPublic}
                                onChange={() =>
                                    setModule(prevState => ({
                                        ...prevState,
                                        isPublic: !prevState.isPublic,
                                    }))
                                }
                                htmlFor="isPublic"
                                valueNames={{ true: "Da", false: "Ne" }}
                            />
                        </DisplayOrInput>
                    </span>

                    {!module.isPublic && (
                        <>
                            <span className="modal-details-label">Uloge</span>
                            <RoleSelector
                                disabled={!isAdd && !isEdit}
                                columns={3}
                                value={module?.roles}
                                setValues={roles =>
                                    setModule(prevState => ({ ...prevState, roles }))
                                }
                                errorMessage={getErrorMsg("roles")}
                            />
                        </>
                    )}
                </div>
                {(module.created !== "" || module.lastUpdated !== "") && (
                    <>
                        <div className="modal-section narrow">
                            <FingerprintDisplay
                                item={module}
                                value="created"
                                containerClassName="modal-section no-border"
                                labelClassName="modal-details-label"
                                textClassName="modal-details-text"
                            />
                        </div>
                        <div className="modal-section narrow">
                            <FingerprintDisplay
                                item={module}
                                value="lastUpdated"
                                containerClassName="modal-section no-border"
                                labelClassName="modal-details-label"
                                textClassName="modal-details-text"
                            />
                        </div>
                        <div className="modal-section narrow">
                            {/* dummy div, for underline */}
                        </div>
                    </>
                )}
            </div>
            <ConfirmationModal
                isActive={showDelete}
                onConfirm={onDelete}
                onCancel={() => setShowDelete(false)}
                content={`Želite li izbrisati modul ${module?.name}?`}
            />
        </Modal>
    );
};
