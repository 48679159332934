import React from "react";
import { Filter, Checkbox, Loading } from "../";

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    isActive: boolean;
    showDropdown: boolean;
    expertAreasFields: string[];
    loadingExpertAreas: boolean;
    expertAreasFilter: string[];
}

export const ExpertAreasFilter: React.FC<Props> = ({
    onChange,
    onClick,
    onClear,
    isActive,
    showDropdown,
    expertAreasFields,
    loadingExpertAreas,
    expertAreasFilter,
}) => {
    return (
        <Filter
            isActive={isActive}
            showDropdown={showDropdown}
            onClear={onClear}
            onClick={onClick}
            text="Stručno podr."
        >
            {loadingExpertAreas ? (
                <Loading />
            ) : (
                expertAreasFields.map((value, index) => (
                    <div key={index}>
                        <Checkbox
                            type="checkbox"
                            value={value}
                            checked={Boolean(expertAreasFilter.includes(value))}
                            onChange={onChange}
                            htmlFor={value}
                            text={<div className="filter__item__text">{value}</div>}
                        />
                    </div>
                ))
            )}
        </Filter>
    );
};
