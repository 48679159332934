import { useCallback, useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { Button } from "../Button";

type Props = {
    handleDrop: (file: File) => void;
    accept?: Accept;
    isValid: boolean;
    errorMessage: string;
};

export const Dropzone = ({ handleDrop, accept, isValid = true, errorMessage }: Props) => {
    const [file, setFile] = useState<File | null>(null);

    const onDrop = useCallback(
        acceptedFiles => {
            handleDrop(acceptedFiles[0]);
            setFile(acceptedFiles[0]);
        },
        [handleDrop]
    );
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: accept,
        onDrop,
        maxFiles: 1,
    });

    return (
        <>
            {file && (
                <div className="dropzone-content">
                    <span>{file.name}</span>
                </div>
            )}
            <div className="dropzone-container">
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dropzone-content">
                        <span>Povuci i ispusti ovdje datoteku</span>
                        <span> ili</span>
                        <Button size="small" isDisabled={false} variant="primary">
                            Odaberi datoteku
                        </Button>
                    </div>
                </div>
            </div>
            {!isValid && <p className="error-message">{errorMessage}</p>}
        </>
    );
};
