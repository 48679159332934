import { CustomTable, Button, TableType } from "modules/common";
import React, { useMemo } from "react";
import { ReactComponent as LocationPin } from "assets/icons/location-pin.svg";
import { format } from "date-fns";
import { EducationItem } from "services";
import { ReactComponent as DetailsIcon } from "assets/icons/details.svg";
import { SpecificEducation } from "./SpecificEducation";

interface Props {
    items: EducationItem[];
    onClick: (value: number) => void;
    filter: number;
    selectedEducation?: EducationItem | null;
    setSelectedEducation: (value?: EducationItem | null) => void;
    currentTab: string;
}

export const EducationList: React.FC<Props> = ({
    items,
    onClick,
    filter,
    selectedEducation,
    setSelectedEducation,
    currentTab,
}) => {
    function formatTime(data: string) {
        const date = new Date(data);

        return format(date, "HH:mm");
    }

    const formatDateTime = (startDate: Date, endDate: Date) => {
        const dayName = startDate.toLocaleDateString("hr-HR", { weekday: "long" });
        const formattedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

        const formattedDate = startDate
            .toLocaleDateString("hr-HR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            })
            .replace(/\s/g, "");

        const startTime = formatTime(startDate.toISOString());
        const endTime = formatTime(endDate.toISOString());

        return (
            <div>
                <div>{`${formattedDayName} ${formattedDate}`}</div>
                <div className="education-time-row">{`${startTime} - ${endTime}`}</div>
            </div>
        );
    };

    const handleViewDetails = (education: EducationItem) => {
        setSelectedEducation(education);
    };

    const tableColumns = useMemo(() => {
        const columns = [
            {
                Header: "NAZIV",
                disableSortBy: true,
                accessor: "title",
            },
            {
                Header: "ADRESA",
                disableSortBy: true,
                accessor: "address",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return (
                        <div className="education-address-cell">
                            <span>{original.address}</span>
                            <LocationPin className="details-icon location-icon" />
                        </div>
                    );
                },
            },
            {
                Header: "DATUM",
                disableSortBy: true,
                accessor: (data: EducationItem) => {
                    const startDate = new Date(data.startDate);
                    const endDate = new Date(data.endDate);
                    return formatDateTime(startDate, endDate);
                },
            },
            {
                Header: "VIŠE",
                id: "info",
                disableSortBy: true,
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return (
                        <DetailsIcon
                            className="details-icon"
                            onClick={() => handleViewDetails(original)}
                        />
                    );
                },
            },
        ];

        if (currentTab === "Prijavljeno") {
            columns.push({
                Header: "",
                id: "centered",
                disableSortBy: true,
                Cell: () => {
                    return (
                        <Button
                            className="educations-table-button"
                            type="button"
                            size="small"
                            variant="primary-ghost"
                            isDisabled={false}
                            onClick={() => {}} // TODO: Implement this functionality
                        >
                            <span>Podsjeti me</span>
                        </Button>
                    );
                },
            });
        }

        if (currentTab === "Odslušano") {
            columns.push({
                Header: "",
                id: "centered",
                disableSortBy: true,
                Cell: () => {
                    return (
                        <Button
                            className="educations-table-button"
                            type="button"
                            size="small"
                            variant="primary"
                            isDisabled={false}
                            onClick={() => {}} // TODO: Implement this functionality
                        >
                            <span>Zatraži potvrdu</span>
                        </Button>
                    );
                },
            });
        }

        return columns;
    }, [currentTab, onClick]);

    return selectedEducation ? (
        <SpecificEducation
            selectedEducation={selectedEducation}
            filter={filter}
            setSelectedEducation={setSelectedEducation}
            onClick={onClick}
        />
    ) : (
        <div className="table-container">
            <CustomTable
                data={items}
                columns={tableColumns}
                tableType={TableType.EducationsTable}
            />
        </div>
    );
};
