import React from "react";
import Select, { Props as SelectProps, components } from "react-select";
import { ReactComponent as DefaultDropdownIcon } from "assets/icons/chevron-down.svg";

interface BaseOption {
    id: number | string;
    [key: string]: any;
}

interface OptionType<T> {
    value: number | string;
    label: string;
    data: T;
}

interface Props<T extends BaseOption>
    extends Omit<SelectProps<OptionType<T>, false>, "options" | "onChange"> {
    items?: T[];
    labelKey: keyof T;
    onSelect: (item: T | undefined) => void;
    customIcons?: {
        searchIcon?: React.ReactNode;
        dropdownIcon?: React.ReactNode;
    };
}

const SearchableDropdown = <T extends BaseOption>({
    items,
    labelKey,
    onSelect,
    customIcons,
    placeholder = "Pretraga...",
    isValid = true,
    errorMessage,
    ...props
}: Props<T>) => {
    const options: OptionType<T>[] = React.useMemo(
        () =>
            items
                ? items.map(item => ({
                      value: item.id,
                      label: String(item[labelKey]),
                      data: item,
                  }))
                : [],
        [items, labelKey]
    );

    const handleChange = React.useCallback(
        (option: OptionType<T> | null) => {
            onSelect(option?.data);
        },
        [onSelect]
    );

    const DropdownIndicator = React.useCallback(
        (props: any) => (
            <components.DropdownIndicator {...props}>
                {customIcons?.dropdownIcon || <DefaultDropdownIcon width={14} height={14} />}
            </components.DropdownIndicator>
        ),
        [customIcons?.dropdownIcon]
    );

    const Control = React.useCallback(
        ({ children, ...props }: any) => (
            <>
                <components.Control {...props}>
                    <div
                        className={`control-container ${
                            customIcons?.searchIcon ? "has-search-icon" : ""
                        }`}
                    >
                        {customIcons?.searchIcon && (
                            <div className="search-icon-wrapper">{customIcons.searchIcon}</div>
                        )}
                        {children}
                    </div>
                </components.Control>
                {!isValid && <p className="error-message">{errorMessage}</p>}
            </>
        ),
        [customIcons?.searchIcon]
    );

    return (
        <Select<OptionType<T>>
            className="searchable-dropdown"
            classNamePrefix="searchable-dropdown"
            options={options}
            onChange={handleChange}
            placeholder={placeholder}
            components={{
                DropdownIndicator,
                Control,
            }}
            isSearchable
            {...props}
        />
    );
};

export default SearchableDropdown;
