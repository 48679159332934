import React from "react";
import { Row, TableBodyPropGetter, TableBodyProps } from "react-table";
import { TableType } from "./Table";

interface Props {
    getTableBodyProps: (propGetter?: TableBodyPropGetter<object>) => TableBodyProps;
    rows: Row<object>[];
    prepareRow: (row: Row<object>) => void;
    tableType: TableType;
}
export const TableBody: React.FC<Props> = ({ getTableBodyProps, rows, prepareRow, tableType }) => {
    return (
        <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row);
                return (
                    <tr className="table-row" {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                            const columnClass = cell.column.className ?? "";
                            const baseClass =
                                tableType === TableType.AdministrationTable
                                    ? "administration-table-data"
                                    : "education-table-data";

                            const additionalClass =
                                baseClass === "education-table-data" && cell.column.id === "info"
                                    ? "education-table-data-info"
                                    : "";

                            return (
                                <td
                                    className={`${baseClass} ${columnClass} ${additionalClass}`}
                                    {...cell.getCellProps()}
                                >
                                    {cell.render("Cell")}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};
