import { getAuthHeaders, getFormData } from "modules/common";
import { createService } from "services/serviceFactory";
import {
    CreateRecordRequest,
    GetRecordRequest,
    EditRecordRequest,
    CreateRecordCategoryRequest,
    EditRecordCategoryRequest,
} from "./request";
import { Record, RecordCategory, RecordResponse } from "./response";

const recordsService = createService("records");

async function getRecords(request: GetRecordRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await recordsService.get<RecordResponse>("/records/v1/records", {
        params: request,
        ...authHeaders,
    });
    return response.data;
}

async function createRecord(
    data: CreateRecordRequest,
    uploadedFile: File | null,
    accessToken: string
) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = new FormData();
    formData.append("recordDtoJson", JSON.stringify(data));
    if (uploadedFile) {
        formData.append("formFile", uploadedFile);
    }
    const response = await recordsService.post<Record>(
        "/records/v1/records",
        formData,
        authHeaders
    );
    return response.data;
}

async function editRecord(data: EditRecordRequest, uploadedFile: File | null, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = new FormData();
    formData.append("recordDtoJson", JSON.stringify(data));
    if (uploadedFile) {
        formData.append("formFile", uploadedFile);
    }
    const response = await recordsService.patch<Record>(
        `/records/v1/records`,
        formData,
        authHeaders
    );
    return response.data;
}

async function bulkEditRecords(
    data: EditRecordRequest,
    recordGuids: string[],
    accessToken: string
) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = getFormData(data);
    formData.append("recordGuids", recordGuids.join(","));
    const response = await recordsService.patch<Record>(
        `/records/v1/records/bulk`,
        formData,
        authHeaders
    );
    return response.data;
}

async function deleteRecord(id: string, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await recordsService.delete<Record>(`/records/v1/records/${id}`, authHeaders);
    return response.data;
}

async function getRecordCategories(searchTerm: string, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await recordsService.get<RecordCategory[]>(`/records/v1/records/categories`, {
        params: { searchTerm },
        ...authHeaders,
    });
    return response.data;
}

async function createRecordCategory(data: CreateRecordCategoryRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = getFormData(data);
    const response = await recordsService.post<RecordCategory>(
        "/records/v1/records/categories",
        formData,
        authHeaders
    );
    return response.data;
}

async function editRecordCategory(data: EditRecordCategoryRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = getFormData(data);
    const response = await recordsService.patch<RecordCategory>(
        `/records/v1/records/categories`,
        formData,
        authHeaders
    );
    return response.data;
}

async function downloadRecordFile(id: string, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await recordsService.get(`/records/v1/records/download/${id}`, {
        responseType: "blob",
        ...authHeaders,
    });
    return response.data;
}

export {
    getRecords,
    createRecord,
    editRecord,
    bulkEditRecords,
    deleteRecord,
    getRecordCategories,
    createRecordCategory,
    editRecordCategory,
    downloadRecordFile,
};
