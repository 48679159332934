import { Button, Checkbox, CustomTable, OptionType, Pagination, TableType } from "modules/common";
import { useMemo, useState } from "react";
import { SortingRule } from "react-table";
import { RecordResponse } from "services/records/response";
import { Record } from "services/records";
import { getDisplayDateFormat } from "helpers/DatesHelper";
import { RecordDetailsModal } from "./RecordDetailsModal";
import { downloadFile } from "../hooks";
import { RootState } from "store";
import { useSelector } from "react-redux";

export interface IRecordsTable {
    records?: RecordResponse;
    isLoading?: boolean;
    setSortBy?: (sortBy: SortingRule<object>) => void;
    page?: number;
    pageSize?: number;
    onPageSizeChange?: (value: number) => void;
    onPageChange?: (page: number) => void;
    className?: string;
    selectedRows: Record[];
    setSelectedRows: (rows: Record[]) => void;
}

const DefaultPageSize = {
    value: "10",
    label: "10",
};

export const RecordsTable = ({
    records,
    isLoading,
    setSortBy,
    page,
    pageSize,
    onPageSizeChange,
    onPageChange,
    className,
    selectedRows,
    setSelectedRows,
}: IRecordsTable) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<Record>();
    const accessToken = useSelector((state: RootState) => state.signIn.user?.accessToken);

    const tableColumns: any[] = [
        {
            accessor: "checkbox",
            disableSortBy: true,
            width: 2,
            Cell: ({ row }: any) => {
                const { original } = row;
                return (
                    <Checkbox
                        checked={selectedRows.some(row => row.guid === original.guid)}
                        onChange={event => {
                            if (event.target.checked) {
                                setSelectedRows([...selectedRows, original]);
                            } else {
                                setSelectedRows(
                                    selectedRows.filter(row => row.guid !== original.guid)
                                );
                            }
                        }}
                        type="checkbox"
                        htmlFor={original.guid}
                        text=""
                    />
                );
            },
        },
        {
            Header: "NAZIV",
            accessor: "name",
            disableSortBy: true,
            width: 10,
            Cell: ({ row }: any) => {
                const { original } = row;
                return (
                    <span
                        className="modal-name"
                        onClick={() => {
                            setModalOpen(true);
                            setSelectedRow(original);
                        }}
                    >
                        {original.name}
                    </span>
                );
            },
        },
        {
            Header: "ROK ISPUNJENJA",
            accessor: "deadline",
            disableSortBy: true,
            width: 4,
            Cell: ({ row }: any) => {
                const { original } = row;
                return (
                    <span className="modal-name">{getDisplayDateFormat(original.deadline)}</span>
                );
            },
        },
        {
            Header: "PREUZIMANJE",
            id: "actions",
            disableSortBy: true,
            width: 4,
            className: "actions-cell",
            Cell: ({ row }: any) => {
                return (
                    <Button
                        size="small"
                        variant="primary-ghost"
                        onClick={() => {
                            const { original } = row;
                            downloadFile(original.guid, accessToken!);
                        }}
                        isDisabled={false}
                    >
                        PREUZMI
                    </Button>
                );
            },
        },
    ];

    const activePageSize = useMemo(() => {
        return pageSize
            ? { value: pageSize.toString(), label: pageSize.toString() }
            : DefaultPageSize;
    }, [pageSize]);

    const classNameString = useMemo(() => {
        return `records-table ${className ?? ""}`;
    }, [className]);

    const handlePageSizeChange = (option: OptionType | null) => {
        onPageSizeChange && onPageSizeChange(parseInt(option?.value ?? DefaultPageSize.value));
    };

    const handlePageClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const target = event.currentTarget ?? event.target;
        onPageChange && onPageChange(Number(parseInt(target.id)));
    };

    const handleNexPageClick = () => {
        onPageChange && onPageChange(page ? page + 1 : 1);
    };

    const handlePrevPageClick = () => {
        onPageChange && onPageChange(page ? page - 1 : 1);
    };

    return (
        <div className={classNameString}>
            <CustomTable
                flex
                data={records?.items ?? []}
                columns={tableColumns}
                loading={isLoading}
                setSortBy={setSortBy}
                tableType={TableType.AdministrationTable}
            />

            {onPageChange && records && records.items.length > 0 && (
                <Pagination
                    className="mt-24"
                    currentPage={page ?? 1}
                    numberOfPages={Math.ceil(records.total / parseInt(activePageSize.value))}
                    pageNeighbours={1}
                    pageSize={activePageSize}
                    onPageSizeChange={handlePageSizeChange}
                    handlepageClick={handlePageClick}
                    next={handleNexPageClick}
                    prev={handlePrevPageClick}
                />
            )}

            {modalOpen && (
                <RecordDetailsModal
                    isOpen={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    records={selectedRow ? [selectedRow] : []}
                />
            )}
        </div>
    );
};
